import { tap, map } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Reward } from '@core/models/reward.model';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subject, Subscription, forkJoin, Observable } from 'rxjs';
import { RewardDataService } from '../../services/reward-data.service';
import { RewardSwalComponent } from './reward-swal/reward-swal.component';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import Swal from 'sweetalert2';
import svgIconList from 'assets/icons.json';
@Component({
  selector: 'app-claim-rewards-modal',
  templateUrl: './claim-reward-modal.component.html',
  styleUrls: ['./claim-reward-modal.component.scss']
})
export class ClaimRewardModalComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  form: FormGroup;
  gameCategoryList: any;
  providersDropdown = [];
  refreshStatus: boolean;
  checkValidity = false;
  selectedTransferFrom = {};
  selectedTransferTo = {};
  memberBalance: any;
  providerList = [];
  isSuccess = false;


  currencyCode = localStorage.getItem('country_code');
  showIDRRate = new Subject<boolean>();
  providerName: string;
  provider: number;
  selectedReward: any;

  buttonLoading = false;
  
  private subscriptions: Subscription[] = [];
  private subscription = new Subscription();

  promoTransferSuccess = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { reward: Reward },
    public dialogRef: MatDialogRef<ClaimRewardModalComponent>,
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private rewardDataService: RewardDataService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService,
    private transferService: PortalTransferHttpService,
    private translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit() {
    this.setProvidersDropdown();
    this.formInit(this.data.reward);
    const data = {
      ...this.form.value
    }
    
    this.eventEmitterService.updateProfileErrorCloseAllModalEmitter.subscribe(() => {
      this.onCloseDialog();
    })
  }

  ngOnDestroy() {
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  submit() {
    this.checkValidity = true;
    if (this.form.valid) {
      const data = {
        ...this.form.value
      }
      let gameId = 0;
      if (this.data.reward.promo_type === 3) {
        gameId = +data.game_provider_id;
      } else {
        gameId = +data.transfer_to;
      }
      // First we find the wallet balance that user want to transfer to
      const game_balance = this.providersDropdown.find(x => x.id == gameId);
      // If found and the wallet balance is greater than 0
      if (game_balance && game_balance.balance > 0) {
         // Do confirmation pop up
         this.alertPop(game_balance, data);
      } else {
        this.loadingBar.start();
        // If wallet balance is 0, do normal claim function
        this.submitClaimForm(data)
      }
      this.checkValidity = false;
    }
  }

  submitClaimForm(data) {
    this.buttonLoading = true;
    if (this.data.reward.promo_type === 3) {
      this.rewardDataService.claim(data).subscribe((res) => {
        this.isSuccess = res.success;
        this.sucessPromoPopUp(res);
        this.refreshStatus = true;
        // this.onSuccess(res.message);
        this.dialogRef.close();
        this.buttonLoading = false;
        this.loadingBar.complete();
      });
    } else {
      this.transferService.transfer(data).subscribe(res => {
        this.isSuccess = res.success;
        this.refreshStatus = true;
        this.sucessPromoPopUp(res);
        // this.onSuccess(res.message);
        this.dialogRef.close();
        this.buttonLoading = false;
        this.loadingBar.complete();
      });
    }
    let userData = JSON.parse(localStorage.getItem('user_data'))
    let pendingRewards = +userData.pending_reward;
    if (pendingRewards > 0) {
      pendingRewards--;
      userData = { ...userData, pending_reward: pendingRewards }
      localStorage.setItem('user_data', JSON.stringify(userData));
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onProviderChange(event?: any) {
    if (['KISS', 'MEGA', 'MEGAC'].includes(this.gameCategoryList.rows.find(x => x.id == event.target.value).code) && this.currencyCode === 'ID') {
      this.showIDRRate.next(true);
      const provider = this.providersDropdown.find(e => e.id === +event.srcElement.value);
      this.providerName = provider.name;
      this.provider = provider;
    } else {
      this.showIDRRate.next(false);
      this.provider = null;
    }
    const provider = this.providersDropdown.find(e => e.id === +event.srcElement.value);
    this.selectedReward = event;
  }

  onMultiplicand(transferAmount: any, reward: any, selectedProvider?: any){
    var finalBonus = this.onBonusAmount(transferAmount, reward);

    var targetTypeValue;
   
    if (reward.promo_type == 3) { // free credit
      targetTypeValue = reward.amount;
    } else {
      targetTypeValue = reward.target_type === 1 || reward.target_type === 2 ? transferAmount : 0; // 0 for exclude
    }

    // Comment out to not include the balance in game provider wallet for turnover calculation
    // const totalTransferBonusAmount = ((Number(targetTypeValue) + Number(finalBonus) + Number(this.selectedTransferTo[0]['balance'])).toFixed(3).slice(0, -1));
    const totalTransferBonusAmount = ((Number(targetTypeValue) + Number(finalBonus)).toFixed(3).slice(0, -1));
    var finalTotalTransferBonusAmount = Number(totalTransferBonusAmount);
    return Number(finalTotalTransferBonusAmount);
  }

  onTotalTurnOver(transferAmount: number, reward: any, selectedProvider?: any){
    const finalTotalTransferBonusAmount = this.onMultiplicand(transferAmount, reward, selectedProvider);
    const target_multiplier = reward.target_type == 2 ? reward.target_multiplier+1 : reward.target_multiplier;
    return Number(finalTotalTransferBonusAmount) * Number(target_multiplier);
  }

  onBonusAmount(transferAmount: any, reward: any){
    const totalBonus = (transferAmount*reward.bonus_rate).toFixed(3).slice(0,-1);
    var finalBonus = +totalBonus;
    if (+reward['max_bonus'] < +totalBonus && +reward['max_bonus'] > 0){
      finalBonus = +reward['max_bonus'];
    }
    return finalBonus;
  }

  private setProvidersDropdown() {
    this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe(res => {
      this.providersDropdown = res['rows'].filter(game_provider => game_provider.promotion_ids?.includes(this.data.reward.promotion_id));
      // Additional filter for free spin type promo
      if(this.data.reward.promo_type === 4 && this.data.reward.free_spin_game_provider_id > 0) {
        this.providersDropdown = this.providersDropdown.filter(game_provider => game_provider.id === this.data.reward.free_spin_game_provider_id);
      }
      this.gameCategoryList = res;
    });
    this.walletHttpService.getMemberBalance().subscribe((res: any) => {
      this.memberBalance = res.balance;
      this.providerList = this.gameCategoryList.rows;
      this.providerList.unshift({ id: 0, status: 1, name: this.translateService.instant('Main Wallet'), balance: this.memberBalance });
      this.onSelectTransferForm(this.providerList[0]);
    });
  }

  onSelectTransferForm(provider) {
    if (provider.status === 1 && this.data.reward.promo_type !== 3) {
      this.selectedTransferFrom = provider;
      this.form.patchValue({
        amount: provider.balance,
        transfer_from: provider.id
      });
    }
  }

  onSelectTransferTo(event: any) {
    const provider: any = this.providersDropdown.filter(x => x.id == event.target.value);
    this.selectedTransferTo = provider;
    if (provider.status === 1) {
      this.form.patchValue({
        transfer_to: provider.id,
        promotion_id: 0
      });
    }
  }

  private onSuccess(message?: any) {
    this.dialog.open(RewardSwalComponent, {
      width: '800px',
      data: {
        type: 'success',
        message: message,
        provider: this.provider
      }
    });
  }

  private formInit(reward) {
    if(reward.promo_type == 3) { // free credit
      this.form = new FormGroup({
        reward_id: new FormControl(this.data.reward.id),
        game_provider_id: new FormControl(null, [Validators.required]),
        transfer_from: new FormControl(null),
        transfer_to: new FormControl(null),
        amount: new FormControl(null),
        promotion_id: new FormControl(this.data.reward.promotion_id)
      });
      this.form.controls['game_provider_id'].markAsTouched();
    } else {
      this.form = new FormGroup({
        reward_id: new FormControl(this.data.reward.id),
        game_provider_id: new FormControl(null),
        transfer_from: new FormControl(null, [Validators.required]),
        transfer_to: new FormControl(null, [Validators.required]),
        amount: new FormControl(null),
        promotion_id: new FormControl(this.data.reward.promotion_id)
      });
      this.form.controls['transfer_from'].markAsTouched();
      this.form.controls['transfer_to'].markAsTouched();
    }
  }

  private submitDataTwo(data: any): Observable<any> {
    return new Observable(observer => {
      this.transferService.transfer(data).subscribe(res => {
        this.promoTransferSuccess = res.success;
        this.loadingBar.complete();
        observer.next(res);
        observer.complete();
      })
    });
    
  }

  private sucessPromoPopUp(res: any) {
    // The wallet balance will be transferr amount + bonus amount as claiming promotion, the wallet balance will be always 0
    let new_wallet_balance = +res.data.rows.amount + +res.data.rows.bonus_amount;
    // Add currency code to the wallet balance
    const new_wallet_balance_text = res.data.currency_code + ' ' + new_wallet_balance.toFixed(2).toString();
    // Display confirmation popup
    Swal.fire({
      html: '<div class="msg-icon-greentick">' + this.svg.greenTickIcon + '</div>' +
            '<div class="text-center m-t-20">' +
              this.translateService.instant('Congratulations') +
            '</div>' + '<br>' +
            '<div class="text-center">' +
              this.translateService.instant('The promotion has been successfully applied.') +
            '</div>' +
            "<hr style='border-top: 1px solid;'>" + 
            "<div class='form-input-label text-left'>" +
              "<p>" + this.translateService.instant('Game Provider') + "</p>" +
            "</div>" +
            "<div class='input_container'>" +
                "<input type='text' class='form-control form-control-sm form-input-style' value='" + res.data.to_name + "' />" +
            "</div>" +
            "<div class='form-input-label text-left'>" +
              "<p>" + this.translateService.instant('New Wallet Balance') + "</p>" +
            "</div>" +
            "<div class='input_container'>" +
                "<input type='text' class='form-control form-control-sm form-input-style' value='" + new_wallet_balance_text + "' />" +
            "</div>"
    }).then(() => {
      this.onCloseDialog();
    });
  }

  private alertPop(gameData: GameBalance, formData: tranferFormData) {
    // Display confrimation message
    Swal.fire({
      html: "<div class='form-input-label text-left'>" +
            "<p>" + this.translateService.instant('Game Provider') + "</p>" +
            "</div>" +
            "<div class='input_container'>" +
                "<input type='text' class='form-control form-control-sm form-input-style' value='" + gameData.name + "' />" +
            "</div>" +
            "<div class='form-input-label text-left'>" +
              "<p>" + this.translateService.instant('Game Wallet Balance') + "</p>" +
            "</div>" +
            "<div class='input_container'>" +
                "<input type='text' class='form-control form-control-sm form-input-style' value='" + gameData.balance + "' />" +
            "</div>" +
            '<div class="text-center m-t-20" style="color: #0066c9 !important; font-size: 11px">' +
              this.translateService.instant('You have a remaining balance in the selected game wallet.') + '<br>' +
              this.translateService.instant('This balance will be transferred back to the main wallet before the promotion application.') +
            '</div>' + '<br>' +
            '<div class="text-center" style="font-size: 11px">' +
              this.translateService.instant('Click YES to proceed with the transfer and promotion application.') +
            '</div>',
      confirmButtonText: this.translateService.instant('YES'),
      showDenyButton: true,
      showConfirmButton: true,
      denyButtonText: this.translateService.instant('NO'),
      reverseButtons: true,
      showCloseButton: true,
      customClass: {
        denyButton: 'deny-button',
        confirmButton: 'confirm-button',
        container: 'overlap-swal'
      }
    }).then(result => {
      // If yes
      if (result.isConfirmed) {
        // Prepare param for transferring the remaining game wallet balance to main wallet first
        this.promoTransferSuccess = false;
        const dataFromGameToMain = {
          amount: gameData.balance,
          promotion_id: 0,
          transfer_from: gameData.id,
          transfer_to: 0
        }
        this.loadingBar.start();
        this.submitDataTwo(dataFromGameToMain).toPromise()
          .then(() => {
            if (this.promoTransferSuccess) {
              const dataFromMainToGameWithPromo = formData;
              this.loadingBar.start();
              this.submitClaimForm(dataFromMainToGameWithPromo);
            }
          })
          .catch((err) => {
            this.loadingBar.complete();
            throw err;
          });
       
      }
    });
  }

}

class GameBalance {
  balance: string;
  name: string;
  code: string;
  id: number;
}

class tranferFormData {
  amount: number;
  promotion_id: number;
  transfer_from: number;
  transfer_to: number;
}