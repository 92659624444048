<div class="modal-dialog modal-dialog-centered modal show">
  <div class="modal-body">
    <div class="modal-content modal-bg modal-bg-wallet">
      <div class="modal-header">
        <h4 class="modal-title">{{ 'Claim' | translate}} {{ 'Reward' | translate }} {{ data.reward.name ? ' - ' + data.reward.name : '' }}</h4>
        <button type="button" class="close" (click)="onCloseDialog()">&times;</button>
      </div>
      <hr>
      <div class="claim-modal">
        <form [formGroup]="form" id="claim-reward-modal-form" name="claim-reward-modal-form">
          <ng-container *ngIf="data.reward.promo_type === 3; else transferTypePromo">

            <div class="d-flex justify-content-evenly claim-field mb-2" [ngClass]="{ 'is-invalid' : form.controls['game_provider_id'].errors }">
              <div class="p-2 flex flex-fill claim-field-label col-5"><p class="m-0 pt-1 pl-2">{{ 'Transfer To' | translate }}</p></div>
              <div class="p-2 flex flex-fill claim-field-content col-7">
                <select formControlName="game_provider_id" class="col grey" [ngClass]="{'form-invalid dropdown-invalid' : checkValidity && form.controls.game_provider_id.errors}" (change)="onProviderChange($event)">
                  <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
                  <ng-container *ngFor="let item of providersDropdown">
                    <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}}</option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="d-flex justify-content-evenly claim-field mb-2">
              <div class="p-2 flex flex-fill claim-field-label col-5"><p class="m-0 pt-1 pl-2">{{ 'Bonus Amount' | translate }}</p></div>
              <div class="p-2 flex flex-fill claim-field-content col-7 p-0">
                <input class="form-control form-control-sm form-input-style m-0" type="text" placeholder="{{ 'Enter Amount' | translate }}" [value]="data.reward.amount" digitOnly decimal="true" decimalSeparator="." pattern="^\d+(\.\d{1,2})?$" disabled="">
              </div>
            </div>

            <div class="mb-4 mt-2">
              <p *ngIf="form.controls.game_provider_id.errors" class="exchange-font m-b-0 mt-1">
                {{ 'Please select the game wallet to transfer the reward amount.' | translate }}
              </p>
              <ng-container *ngIf="!form.controls.game_provider_id.errors">
                <p class="exchange-font m-b-0 mt-1">
                  {{ 'You are entitled to promotion(s) for this game provider' | translate }}
                </p>
                <p class="exchange-font">
                  {{ (data.reward.target_type == 2 || data.reward.target_type == 4 ? 'Winover Requirement' : 'Turnover Requirement') | translate }}: <br>
                  {{ data.reward.currency_code }} {{ onMultiplicand(+form.value.amount, data.reward) }} x {{ data.reward.target_type == 2 ? data.reward.target_multiplier+1 : data.reward.target_multiplier }} = {{ data.reward.currency_code + ' ' + (onTotalTurnOver(+form.value.amount, data.reward) | number: '1.2-2') }}
                </p>
              </ng-container>
            </div>

            <div class="mt-2" *ngIf="showIDRRate | async">
              <p class="text-blue">
                  {{ 'Exchange Rate' | translate }} <br> IDR 1:0.2 {{ providerName | uppercase }} {{ 'Credits' | translate }}
              </p>
            </div>

          </ng-container>

          <ng-template #transferTypePromo>

            <div class="d-flex justify-content-evenly claim-field mb-2">
              <div class="p-2 flex flex-fill claim-field-label col-5"><p class="m-0 pt-1 pl-2">{{ 'Transfer From' | translate }}</p></div>
              <div class="p-2 flex flex-fill claim-field-content col-7">
                <select formControlName="transfer_from" class="col" [ngClass]="{'form-invalid dropdown-invalid' : checkValidity && form.controls.transfer_from.errors}">
                  <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
                  <ng-container *ngFor="let item of providerList">
                    <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}}</option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="d-flex justify-content-evenly claim-field mb-2" [ngClass]="{ 'is-invalid' : form.controls['transfer_to'].errors }">
              <div class="p-2 flex flex-fill claim-field-label col-5"><p class="m-0 pt-1 pl-2">{{ 'Transfer To' | translate }}</p></div>
              <div class="p-2 flex flex-fill claim-field-content col-7">
                <select formControlName="transfer_to" class="col grey" (change)="onSelectTransferTo($event)">
                  <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
                  <ng-container *ngFor="let item of providersDropdown">
                    <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}}</option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="d-flex justify-content-evenly claim-field mb-2">
              <div class="p-2 flex flex-fill claim-field-label col-5"><p class="m-0 pt-1 pl-2">{{ 'Transfer Amount' | translate }}</p></div>
              <div class="p-2 flex flex-fill claim-field-content col-7 p-0">
                <input class="form-control form-control-sm form-input-style m-0" formControlName="amount" type="number" placeholder="{{ 'Enter Amount' | translate }}">
              </div>
            </div>

            <div class="d-flex justify-content-evenly claim-field mb-2" *ngIf="form.value.transfer_to !== null">
              <div class="p-2 flex flex-fill claim-field-label col-5"><p class="m-0 pt-1 pl-2">{{ 'Bonus Amount' | translate }}</p></div>
              <div class="p-2 flex flex-fill claim-field-content col-7 p-0">
                <input class="form-control form-control-sm form-input-style m-0" type="text" placeholder="{{ 'Enter Amount' | translate }}" [value]="onBonusAmount(+form.value.amount, data.reward)" digitOnly decimal="true" decimalSeparator="." pattern="^\d+(\.\d{1,2})?$" disabled="">
              </div>
            </div>

            <div class="d-flex justify-content-evenly claim-field mb-2" *ngIf="form.controls.transfer_to.errors">
              <p class="exchange-font m-b-0 mt-1">
                {{ 'Please select the game wallet to transfer the reward amount.' | translate }}
              </p>
            </div>

            <div class="mb-4" *ngIf="form.value.transfer_to !== null">
              <p class="exchange-font m-b-0 mt-1">
                {{ 'You are entitled to promotion(s) for this game provider' | translate }}
              </p>
              <p class="exchange-font">
                {{ (data.reward.target_type == 2 || data.reward.target_type == 4 ? 'Winover Requirement' : 'Turnover Requirement') | translate }}: <br>
                {{ data.reward.currency_code }} {{ onMultiplicand(+form.value.amount, data.reward) }} x {{ data.reward.target_type == 2 ? data.reward.target_multiplier+1 : data.reward.target_multiplier }} = {{ data.reward.currency_code + ' ' + (onTotalTurnOver(form.value.amount, data.reward) | number: '1.2-2') }}
              </p>
              <!-- <p class="exchange-font">
                  {{ 'Bonus' | translate }}: {{ data.reward.currency_code + ' ' + onBonusAmount(+form.value.amount, data.reward) }}
              </p> -->
            </div>

          </ng-template>

          <button type="submit" class="btn-blue-gra m-t-0 m-b-20" [ngClass]="form.valid ? '' : 'disabled'" [disabled]="form.valid === false || buttonLoading" (click)="submit()">{{ 'Submit' | translate | uppercase }}</button>
        </form>
      </div>
    </div>
  </div>
</div>