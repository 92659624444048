<div class="side-panel-wrap">
    <div id="side-panel" class="dark scrollable-bar">
        <div class="side-panel-wrap" [ngClass]="showChangeCountryNotice ? 'p-t-120' : 'p-t-10'">
            <div class="widget clearfix">
                <ng-container *ngIf="(isLoggedIn$ | async); else guestTemplate">
                    <ng-container *ngIf="userProfileDetail">
                        <div class="welcome-box m-l-0 m-r-0 m-t-20 m-b-30" (click)="onHideSideBar(false); isOpenGameMenu = false">
                            <div class="welcome-box-image">
                                <img [src]="userProfileDetail.member_group.image" [alt]="userProfileDetail.member_group.name + ' Badge Icon'" *ngIf="userProfileDetail.member_group.image">
                            </div>
                            <div class="m-t-3">
                                <p>{{ 'Welcome' | translate | uppercase}}</p>
                                <a routerLink="/member/account"><h3>{{ userProfileDetail.username ? userProfileDetail.username : userData.username }}</h3></a>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #guestTemplate>
                    <div class="welcome-box m-l-0 m-r-0 m-t-20 m-b-30" (click)="onHideSideBar(false); isOpenGameMenu = false">
                        <img src="/assets/images/dpc/vip-classic.png" [alt]="('Welcome' | translate) + ' Badge Icon'">
                        <div class="m-t-3 welcome-box-text">
                            <p>{{ 'Welcome' | translate | uppercase }}!</p>
                        </div>
                    </div>
                </ng-template>

                <nav class="nav-tree nobottommargin">
                    <ul>
                        <ng-container *ngFor="let menuList of menuLists; let i = index">
                            <ng-container *ngIf="menuList.sub_menu?.length > 0; else singleMenu">
                                <li (click)="isOpenSubMenu[i] ? null : onHideSideBar(true); isOpenSubMenu[i] = !isOpenSubMenu[i]" class="dropdown-li" [ngClass]="{'active-dropdown': isOpenSubMenu[i]}">
                                    <a class="sidebarmenu menuicnhome dropdown-menu-no-hover" [ngClass]="{'active': isActiveSubMenu[i] > 0}">
                                        <div class="row h-100 vertical-center">
                                            <div class="col-3">
                                                <span [innerHTML]="menuList.icon | safeHtml"></span>
                                            </div>
                                            <div class="col-9 d-flex justify-content-between" >
                                                <div *ngIf="(!isOpenSubMenu[i] && checkSubMenu(menuList.sub_menu)) || menuList.new" class="prediction-notification" ></div>
                                                <span>{{ menuList.name }}</span>
                                                <ng-container *ngIf="isActiveSubMenu[i] > 0; else elseTemplate">
                                                    <i [ngClass]="!isOpenSubMenu[i] ? 'icon-chevron-down font-color-1' : 'icon-chevron-up font-color-1'"></i>
                                                </ng-container>
                                                <ng-template #elseTemplate>
                                                    <i [ngClass]="!isOpenSubMenu[i] ? 'icon-chevron-down' : 'icon-chevron-up'"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="dropdown-container" *ngIf="isOpenSubMenu[i] || isActiveSubMenu[i] > 0" [@dropDownMenu]>
                                        <div class="dropdown-item-link" *ngFor="let subMenu of menuList.sub_menu" (click)="isOpenSubMenu[i]=true;onDropdownNavigate(subMenu);" [ngClass]="{'position-relative': subMenu.new }">
                                            <div *ngIf="subMenu.new" class="prediction-notification" style="right: 5%!important;"></div>
                                            <a class="d-flex align-items-center"><span>{{ subMenu.name }}</span></a>
                                        </div>
                                    </div>
                                </li>
                            </ng-container>

                            <ng-template #singleMenu>
                                <ng-container *ngIf="menuList?.link?.startsWith('/'); else popupMenu">
                                    <li (click)="onHideSideBar(false)">
                                        <a class="sidebarmenu" (click)="navigateTo(menuList)" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="{'active': checkActiveRoute(menuList.link)}">
                                            <div class="row h-100 vertical-center">
                                                <div class="col-3">
                                                    <span [innerHTML]="menuList.icon | safeHtml">
                                                </span>
                                                </div>
                                                <div class="col-9" [ngClass]="{'position-relative': menuList?.link == '/prediction'|| menuList.new }">
                                                    <div *ngIf="(menuList?.link == '/prediction' && !isPredictionTabClicked) || menuList.new" class="prediction-notification"></div>
                                                    <span>{{ menuList.name }}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ng-container>

                                <ng-template #popupMenu>
                                    <li (click)="onHideSideBar(false)">
                                        <a class="sidebarmenu" (click)="onOpenDialog(menuList?.link)">
                                            <div class="row h-100 vertical-center">
                                                <div class="col-3">
                                                    <span [innerHTML]="menuList.icon | safeHtml"></span>
                                                </div>
                                                <div class="col-9" [ngClass]="{'position-relative': menuList.new }">
                                                    <div *ngIf="menuList.new" class="prediction-notification"></div>
                                                    <span>{{ menuList.name }}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ng-template>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngIf="(isLoggedIn$ | async); else elseLoggedIn">
                            <li routerLinkActive="active" (click)="onHideSideBar(false); isOpenGameMenu = false">
                                <a class="sidebarmenu menuicnhome" (click)="onLogout()" routerLinkActive="active">
                                    <div class="row">
                                        <div class="col-3 logout-svg">
                                            <span [innerHTML]="svg.logoutIcon | safeHtml"></span>
                                        </div>
                                        <div class="col-9">
                                            <span>{{ 'Logout' | translate }}</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ng-container>
                        <ng-template #elseLoggedIn>
                            <li (click)="onHideSideBar(false); isOpenGameMenu = false">
                                <a class="sidebarmenu menuicnhome" (click)="onOpenDialog('login')">
                                    <div class="row">
                                        <div class="col-3 logout-svg">
                                            <span [innerHTML]="svg.loginIcon | safeHtml"></span>
                                        </div>
                                        <div class="col-9">
                                            <span>{{ 'Login' | translate }}</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ng-template>
                        <li (click)="onHideSideBar(false); isOpenGameMenu = false">
                            <div class="version-code">
                                <p>V {{ version }}</p>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="countryCode === 'TH' && isLine">
  <div class="line-floating">
    <a>
      <img src="/assets/images/social-icon/line-float.png" (click)="onOpenLine()">
    </a>
  </div>
</ng-container>