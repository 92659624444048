import { NgModule } from '@angular/core';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { LivechatWidgetModule } from '@livechat/angular-widget';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SharedModule } from '../shared/shared.module';
import { BaseInnerComponent } from './partials/base-inner/base-inner.component';
import { BottombarComponent } from './partials/bottombar/bottombar.component';
import { FooterWrapperComponent } from './partials/footer-wrapper/footer-wrapper.component';
import { GameMenuComponent } from './partials/game-menu/game-menu.component';
import { SidebarComponent } from './partials/sidebar/sidebar.component';
import { SidePanelsComponent } from './partials/sidepanels/sidepanels.component';
import { SliderComponent } from './partials/slider/slider.component';
import { TopbarComponent } from './partials/topbar/topbar.component';
import { WalletBarComponent } from './partials/wallet-bar/wallet-bar.component';
import { DynamicFooterComponent } from './partials/dynamic-footer/dynamic-footer.component';
import { JackpotSliderComponent } from './partials/jackpot-slider/jackpot-slider.component';
import { Ng2OdometerModule } from 'ng2-odometer';
import { CarouselModule } from 'ngx-owl-carousel-o';

const partials = [
  BaseInnerComponent,
  BottombarComponent,
  SidebarComponent,
  TopbarComponent,
  SliderComponent,
  SidePanelsComponent,
  FooterWrapperComponent,
  GameMenuComponent,
  WalletBarComponent,
  DynamicFooterComponent,
  JackpotSliderComponent
];

const components = [
  ...partials
];

@NgModule({
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ],
  imports: [
    SharedModule,
    LivechatWidgetModule,
    NgxUsefulSwiperModule,
    Ng2OdometerModule,
    CarouselModule
  ],
  providers: [
  ]
})
export class ViewsModule { }
