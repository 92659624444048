<app-bottombar id="footer-menu" (click)="onClickFooterMenu()" *ngIf="!onMaintenancePage" [style]="(mode == 'Members Only' && !(isLoggedIn$ | async)) ? 'background-color: unset; box-shadow: unset !important' : ''">
  <ng-container *ngIf="mode != 'Members Only' && !(isLoggedIn$ | async) && !isAuthStandAlone">
    <div class="row">
      <div class="col-6 signin">
          <a (click)="onOpenDialog('login')">
              <h4>{{ 'Login' | translate }}</h4>
          </a>
      </div>
        <div class="col-6 signup">
            <a (click)="onOpenDialog('signup')">
                <h4>{{ 'Join' | translate }}</h4>
            </a>
        </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(isLoggedIn$ | async)">
    <ul class="bottom-menu">
      <li>
        <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [ngClass]="pathname == '/' ? 'active' : ''">
          <span [innerHTML]="svg.homeIcon | safeHtml"></span>
          <h6>{{ 'Home' | translate }}</h6>
        </a>
      </li>

      <li>
        <a routerLink="/member/account" routerLinkActive="active">
          <ng-container *ngIf="memberGroupImage; else elseGroupImage">
            <img [src]="memberGroupImage">
          </ng-container>
          <ng-template #elseGroupImage>
            <span [innerHTML]="svg.profileIcon | safeHtml"></span>
          </ng-template>
          <h6>{{ 'Account' | translate }}</h6>
          <span *ngIf="verificationRejectedPending == true" class="badge-btn bottom-bar-badge no-content-badge" matBadge="-" matBadgeColor="warn"></span>
        </a>
      </li>
      <li>
        <a routerLink="/member/wallet" routerLinkActive="active">
          <span [innerHTML]="svg.walletIcon | safeHtml"></span>
          <h6>{{ 'Wallet' | translate }}</h6>
          <span *ngIf="depositData != null" class="badge-btn bottom-bar-badge no-content-badge" matBadge="-" matBadgeColor="warn"></span>
        </a>
      </li>
      <li>
        <a routerLink="/promotion" routerLinkActive="active">
          <span [innerHTML]="svg.promotionIcon | safeHtml"></span>
          <h6>{{ 'Promotion' | translate }}</h6>
        </a>
      </li>
      <li>
        <a (click)="openLiveChat()">
        <!-- <a routerLink="/livechat" class="bottomicnlivechat" routerLinkActive="bottomicnlivechat-active"> -->
          <span [innerHTML]="svg.livechatIcon | safeHtml"></span>
          <h6>{{ 'Live Chat' | translate }}</h6>
        </a>
      </li>
    </ul>
  </ng-container>
</app-bottombar>

