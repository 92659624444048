import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class  EventEmitterService {

  reloadMessageEmitter = new EventEmitter();
  reloadBankListEmitter = new EventEmitter();
  reloadUnreadMessageEmitter = new EventEmitter();
  selectInputEmitter = new EventEmitter();
  removeBodyClassEmitter = new EventEmitter();
  openVerificationEmitter = new EventEmitter();
  verificationTimerEmitter = new EventEmitter();
  popUpAnnouncementEmitter = new EventEmitter();
  buttonEmitter = new EventEmitter();
  availableProviderEmitter = new EventEmitter();
  topBarTabEmitter = new EventEmitter();
  slideAnnouncementEmitter = new EventEmitter();
  swalAnnouncementEmitter = new EventEmitter();
  selectProviderTabEmitter = new EventEmitter();
  dismissMoreSortEmitter = new EventEmitter();
  updateFavoriteEmitter = new EventEmitter();
  updateProfileNameEmitter = new EventEmitter();
  passGameType = new EventEmitter();
  metaTagDefault = new EventEmitter();
  updateContactUsEmitter = new EventEmitter();
  updateCountryAndLanguageEmitter = new EventEmitter();
  updateRewardBubbleEmitter = new EventEmitter();
  updateverificationEmitter = new EventEmitter();
  updateBankListEmitter = new EventEmitter();
  updateVerificationStorageEmitter = new EventEmitter();
  updateMemberMerchantBankSessionEmitter = new EventEmitter();
  jackpotDataEventEmitter = new EventEmitter();

  dismissMoreSortVar: Subscription;
  bankListVar: Subscription;
  subsVar: Subscription;
  unreadMessageVar: Subscription;
  removeBodyClassVar: Subscription;
  popUpAnnouncementVar: Subscription;
  swalAnnouncementVar: Subscription;
  buttonVar: Subscription;
  availableProviderVar: Subscription;
  topBarTabVar: Subscription;
  slideAnnouncementVar: Subscription;
  selectProviderTabVar: Subscription;
  updateFavoriteVar: Subscription;
  getGameType: Subscription;
  contactUsVar: Subscription;
  sideBarGameCategoryVar: Subscription;

  liveChatWidgetEmitter = new EventEmitter();
  liveChatWidgetVar: Subscription;

  setPromotionEmitter = new EventEmitter();
  setPromotionVar: Subscription;

  countryNoticeEmitter = new EventEmitter();
  countryNoticeVar: Subscription;
  slotsGamePageEmitter = new EventEmitter();
  slotsGamePage: Subscription;

  promotionTooltipEmitter = new EventEmitter();
  restoreInfoEmitter = new EventEmitter();
  restoreEmitter = new EventEmitter();
  demoSlideDownEmitter = new EventEmitter();
  gameProviderDemoModeEmitter = new EventEmitter();
  sideBarGameCategoryEmitter = new EventEmitter();

  memberPromotionDetailTopBarTextEmitter = new EventEmitter();
  marqueesEmitter = new EventEmitter();
  marqueesEmitterVar: Subscription;

  goToDepositStepTwoEmitter = new EventEmitter();
  casinoGamePageEmitter = new EventEmitter();
  casinoGamePage: Subscription;
  updatePromotionListEmitter = new EventEmitter();
  updateProfileErrorCloseAllModalEmitter = new EventEmitter();

  sidepanelEventClicked = new EventEmitter();
  sidepanelEventClickedVar: Subscription;

  eventListStatusUpdate = new EventEmitter();
  eventListStatusUpdateVar = new Subscription;

  eventListStatusLabelUpdate = new EventEmitter();
  eventListStatusLabelUpdateVar = new Subscription();
  
  checkNewMenuEmitter = new EventEmitter();
  checkNewMenuVar: Subscription;

  smarticoDataEventEmitter = new EventEmitter();
  updateVIPEmitter = new EventEmitter();
  updateVIPVar: Subscription;
  updateMemberGroupDetailsEmitter = new EventEmitter();

  constructor() { }

  onSetPromotion() {
    this.setPromotionEmitter.emit();
  }

  onLiveChatWidget() {
    this.liveChatWidgetEmitter.emit();
  }

  onUpdateFavorite() {
    this.updateFavoriteEmitter.emit();
  }

  onDismissMoreSort(page: string) {
    this.dismissMoreSortEmitter.emit(page);
  }

  onReloadMessage() {
    this.reloadMessageEmitter.emit();
  }

  onReloadBankList() {
    this.reloadBankListEmitter.emit();
  }

  onReloadUnreadMessag() {
    this.reloadUnreadMessageEmitter.emit();
  }

  onselectInput() {
    this.selectInputEmitter.emit();
  }

  onRemoveBodyClass(isDropDown: boolean) {
    this.removeBodyClassEmitter.emit(isDropDown);
  }

  onOpenVerificationEmitter() {
    this.openVerificationEmitter.emit();
  }

  onVerificationTimerEmitter() {
    this.verificationTimerEmitter.emit();
  }

  onPopUpAnnouncementEmitter() {
    this.popUpAnnouncementEmitter.emit();
  }

  onButtonEmitter() {
    this.buttonEmitter.emit();
  }

  onAvailableProviderEmitter() {
    this.availableProviderEmitter.emit();
  }

  onTopBarTabEmitter() {
    this.topBarTabEmitter.emit();
  }

  onSlideAnnouncementEmitter() {
    this.slideAnnouncementEmitter.emit();
  }

  onSwalAnnouncementEmitter(page: string) {
    this.swalAnnouncementEmitter.emit(page);
  }

  onSelectProviderTabEmitter(activeProvider: any) {
    this.selectProviderTabEmitter.emit(activeProvider);
  }

  onUpdateProfileName() {
    this.updateProfileNameEmitter.emit();
  }

  gameType(type: string) {
    this.passGameType.emit(type);
  }

  onCountryNotice() {
    this.countryNoticeEmitter.emit();
  }

  onSlotsGamePage(isSlotPage: boolean) {
    this.slotsGamePageEmitter.emit(isSlotPage);
  }

  passDemoMode(value: any) {
    this.gameProviderDemoModeEmitter.emit(value);
  }

  onChangeMemberPromotionTopBarText(value: string = 'Activated Promotion') {
    this.memberPromotionDetailTopBarTextEmitter.emit(value)
  }
  
  onMarqueesEmitter() {
    this.marqueesEmitter.emit();
  }

  onUpdateContactUs() {
    this.updateContactUsEmitter.emit();
  }

  onUpdateCountryAndLanguageEmitter(){
    this.updateCountryAndLanguageEmitter.emit()
  }

  onGoToDepositStepTwoUpdateTitle(title: string) {
    this.goToDepositStepTwoEmitter.emit({ title });
  }

  onSideBarGameCategoryEmitter(value: any){
    this.sideBarGameCategoryEmitter.emit(value)
  }
  
  onCasinoGamePage(isCasinoPage: boolean) {
    this.casinoGamePageEmitter.emit(isCasinoPage);
  }

  onUpdateRewardBubble(){
    this.updateRewardBubbleEmitter.emit();
  }

  onUpdateVerificationEmitter() {
    this.updateverificationEmitter.emit();
  }

  onUpdateBankList() {
    this.updateBankListEmitter.emit();
  }

  onVerificationStorageEmitter() {
    this.updateVerificationStorageEmitter.emit();
  }
  onUpdatePromotionListEmitter() {
    this.updatePromotionListEmitter.emit();
  }

  onUpdateProfileErrorCloseAllModalEmitter() {
    this.updateProfileErrorCloseAllModalEmitter.emit();
  }

  onUpdateMemberMerchantBankSessionEmitter() {
    this.updateMemberMerchantBankSessionEmitter.emit();
  }

  onCheckNewMenu(isDropDown: boolean) {
    this.checkNewMenuEmitter.emit(isDropDown);
  }
  
  onInitSmarticoEventEmitter(data:any) {
    this.smarticoDataEventEmitter.emit(data);
  }

  onUpdateVIPEmitter() {
    this.updateVIPEmitter.emit();
  }

  onUpdateMemberGroupDetails(){
    this.updateMemberGroupDetailsEmitter.emit();
  }

  onJackpotEventEmitter(data:any) {
    this.jackpotDataEventEmitter.emit(data);
  }
  
}
