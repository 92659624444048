import { Router } from '@angular/router';
// Angular
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeId from '@angular/common/locales/id';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ErrorHandler } from "@angular/core";
// Service
import { CustomMissingTranslationHandler } from '@core/custom-translate/CustomMissingTranslationHandler';
import { MultiTranslateHttpLoader } from '@core/custom-translate/MultiTranslateHttpLoader';
import { authReducer } from '@core/store/auth/auth.reducers';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthEffects } from './core/store/auth/auth.effects';
import { metaReducers, reducers } from './store/reducers';
import { DynamicFooterService } from '@core/services/dynamic-footer.service';
import { CookieService } from 'ngx-cookie-service';
import { SiteDomainService } from '@core/services/site-domain.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LocationHttpService } from '@core/services/location-http.service';
import { PredictionDataService } from '@views/modules/games/services/prediction-data.service';
// Module
import { LivechatWidgetModule } from '@livechat/angular-widget';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { ViewsModule } from './views/views.module';
// Component
import { AppComponent } from './app.component';
import { PreLoaderComponent } from '@shared/pre-loader/pre-loader.component';
// Other
import { environment } from '@env/environment';
import { CustomParser } from '@core/custom-translate/CustomParser';
// Third Party
import * as Hammer from 'hammerjs';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from "@sentry/angular";
import { ConnectionServiceModule } from 'ng-connection-service';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

export var fingerprintId = null;

// Register locale id for country ID, used in withdraw page & vip page
registerLocaleData(localeId);

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

export function createTranslateLoader(http: HttpClient) {
  // return new TranslateHttpLoader(http,  `//${window.location.host}/assets/i18n/`, '.json');
  return new MultiTranslateHttpLoader(http, [
    { prefix: `${environment.translationDomain}/`, suffix: '.json', fileName: 'message' },
    { prefix: `${environment.translationDomain}/`, suffix: '.json', fileName: 'string' },
    { prefix: `${environment.translationDomain}/`, suffix: '.json', fileName: 'validation' },
    { prefix: `${environment.translationDomain}/`, suffix: '.json', fileName: 'vip-tier' }
  ]);
}

export function preloadFunction(deviceService: DeviceDetectorService, siteDomainService: SiteDomainService, dropdownHttpService: DropdownHttpService, locationHttpService: LocationHttpService, predictionDataService: PredictionDataService) {
  return (): Promise<any> => {
    return new Promise<void>(async (resolve, reject) => {
      var currentDomain: string = null, countryCode: string = null, urlParams = new URLSearchParams(window.location.search);
      const
        availableCountryAndLanguages: any = dropdownHttpService.availableCountryAndLanguages,
        nonRegionalDomains: any = dropdownHttpService.nonRegionalDomains,
        checkDeviceType = () => {
          return new Promise<boolean>((resolve, reject) => {
            var
              hostname: string = window.location.hostname,
              desktopPreExt: string = null,
              mobilePreExt: string = null,
              toDomain: string = null,
              crossLoginParam: string = null;

            // Start: Find current domain and country code
            [environment.domainName, environment.altDomainName, environment.altDomainNameTwo].forEach(item => {
              Object.keys(availableCountryAndLanguages[item]).forEach(code => {
                availableCountryAndLanguages[item][code][environment.domainEnv].forEach(domain => {
                  var domainWithPreExt = availableCountryAndLanguages[item][code]['mobilePreExt'][environment.domainEnv] + domain;
                  if (countryCode == null && currentDomain == null && domainWithPreExt == hostname) {
                    countryCode = code;
                    currentDomain = item;
                  }
                });
              })
            });

            // Set default value if not found
            if (currentDomain == null && countryCode == null) {
              countryCode = 'MY';
              currentDomain = environment.domainName;
            }
            // End: Find current domain and country code

            // Start: Check if desktop process
            if (deviceService.isDesktop()) {
              // Start: Landing page redirection checking
              if (environment.mobileDomainName.split('|').includes(hostname)) {
                window.location.href = (environment.https ? 'https://' : 'http://') + environment.domainName + window.location.pathname + '?' + urlParams.toString();
              } else if (environment.altMobileDomainName.split('|').includes(hostname)) {
                window.location.href = (environment.https ? 'https://' : 'http://') + environment.altDomainName + window.location.pathname + '?' + urlParams.toString();
              } else if (environment.altMobileDomainNameTwo.split('|').includes(hostname)) {
                window.location.href = (environment.https ? 'https://' : 'http://') + environment.altDomainNameTwo + window.location.pathname + '?' + urlParams.toString();
              }
              // End: Landing page redirection checking

              // Start: Normal page redirection checking
              desktopPreExt = availableCountryAndLanguages[currentDomain][countryCode]['desktopPreExt'][environment.domainEnv];
              mobilePreExt = availableCountryAndLanguages[currentDomain][countryCode]['mobilePreExt'][environment.domainEnv];
              if (mobilePreExt.length > 0) {
                toDomain = hostname.replace(mobilePreExt, desktopPreExt);
              } else {
                toDomain = desktopPreExt + hostname;
              }

              // Begin:: Set cross device login parameter
              if (!(window.location.pathname).startsWith('/login') && localStorage.getItem('user_token') && localStorage.getItem('user_data')) {
                crossLoginParam = `userToken=${localStorage.getItem('user_token')}&userData=${localStorage.getItem('user_data')}`;
              }
              // End:: Set cross device login parameter

              var params: string = '';
              if (urlParams.size != 0) {
                params = '?' + urlParams.toString();
              }
              if (crossLoginParam != null) {
                params += (params != '' ? '&' : '?') + crossLoginParam;
              }
              window.location.href = (environment.https ? 'https://' : 'http://') + toDomain + window.location.pathname + params;
              // End: Normal page redirection checking

              resolve(false);
            } else {
              resolve(true);
            }
            // End: Check if desktop process
          })
        },
        setUserBrowser = () => {
          return new Promise<void>((resolve, reject) => {
            localStorage.setItem('sessionid_tracker', (+new Date()).toString(16).toUpperCase());
            localStorage.setItem('user_browser', JSON.stringify({
              isDesktop: ((deviceService.device).includes('iPad')) ? true : deviceService.isDesktop(),
              userAgent: deviceService.getDeviceInfo().userAgent
            }));
            resolve();
          })
        },
        getBrowserLocale = (options?: any) => {
          return new Promise<void>((resolve, reject) => {
            var browser_locale: any;
            const defaultOptions = {
              languageCodeOnly: false,
            };
            const opt = {
              ...defaultOptions,
              ...options,
            };
            const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
            if (browserLocales) {
              browserLocales.map(locale => {
                const trimmedLocale = locale.trim();
                browser_locale = opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale.substring(0, 2);
              });
            }
            localStorage.setItem('user_locale', browser_locale);
            resolve();
          })
        },
        setFingerprint = () => {
          return new Promise<void>((resolve, reject) => {
            if (localStorage.getItem('fid') == null) {
              fingerprintId = uuidv4();
              localStorage.setItem('fid', fingerprintId);
              resolve();
            } else {
              fingerprintId = localStorage.getItem('fid');
              resolve();
            }
          })
        },
        getEventList = () => {
          return new Promise<void>((resolve, reject) => {
            const user_id = (JSON.parse(localStorage.getItem('user_data')) && JSON.parse(localStorage.getItem('user_token'))) ? JSON.parse(localStorage.getItem('user_data')).id : null;
            predictionDataService.getEventList(user_id).subscribe(res => {
              localStorage.setItem('event_list', JSON.stringify(res));
              resolve();
            },
            (error) => {  
                if(error){
                  resolve(); 
                }
            });
          })
        },
        getSiteDomain = () => {
          return new Promise<void>((resolve, reject) => {
            // Step 1 : Get site mode from localStorage, if have
            window.mode = localStorage.getItem('site_mode');

            const
              // Clear URL 'mode' params, if have
              clearURLParam = () => {
                if (urlParams.has('mode')) {
                  urlParams.delete('mode');
                }
                urlParams.size > 0 ? window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`) : '';
              },
              // Check URL 'mode' params, if have
              checkURLParams = () => {
                return new Promise<string>((resolve, reject) => {
                  var mode = urlParams.get('mode') == 'clean' ? 'Members Only' : null;
                  resolve(mode);
                })
              },
              localize = (country_code: string) => {
                if (country_code in availableCountryAndLanguages[currentDomain]) {
                  let countryAndLanguage = country_code + '_';
                  switch (country_code) {
                    case 'TH':
                      countryAndLanguage += 'TH';
                      break;
                    case 'ID':
                      countryAndLanguage += 'ID';
                      break;
                    default:
                      countryAndLanguage += availableCountryAndLanguages[currentDomain][country_code].languages[0];
                      break;
                  }
                  urlParams.append('lang', countryAndLanguage);
                } else {
                  urlParams.append('lang', 'MY_EN');
                }
                if (urlParams.has('lang')) {
                  let countryLanguage = urlParams.get('lang').split('_');
                  localStorage.setItem('country_code', countryLanguage[0]);
                  localStorage.setItem('language_code', countryLanguage[1]);
                }
              },
              // Set 'lang' to URL
              setLang = () => {
                return new Promise<void>((resolve, reject) => {
                  if (localStorage.getItem('user_token') == null && !urlParams.has('lang')) {
                    locationHttpService.getIpAddress().subscribe(res => {
                      if (res['country_code']) {
                        localize(res['country_code'].toUpperCase());
                        resolve();
                      } else {
                        const xhr = new XMLHttpRequest();
                        const url = `https://geolocation-db.com/json/${res['ip']}`;
                        xhr.open('GET', url);
                        xhr.send();
                        xhr.onloadend = () => {
                          const address = xhr.response ? JSON.parse(xhr.response) : {};
                          localize((address?.country_code || '').toUpperCase());
                          resolve();
                        }
                      }
                    })
                  } else {
                    resolve();
                  }
                })
              };

            // Step 2 : Get site domain data from API
            siteDomainService.getSiteDomain().subscribe(async (res) => {
              if (res) {
                localStorage.setItem('redirect_site_domain', JSON.stringify(res));

                // Step 3 : Check window mode if not equal to 'Members Only', then proceed to the following logic to get data from site domain data. Else Step 5
                if (window.mode != 'Members Only') {
                  var domainData = res.find(x => x.domain == window.location.hostname);
                  window.mode = domainData ? domainData.type_name : null;

                  // Step 4 : If site domain data didn't have 'Members Only' data, then proceed to check from URL parameters. Else Step 5
                  if ((window.mode == null || window.mode != 'Members Only') && urlParams.has('mode')) {
                    window.mode = await checkURLParams();
                  }
                }
                // Step 5 : In this step, window.mode should have the data we need
                localStorage.setItem('site_mode', window.mode);

                if (window.mode == 'Members Only') {
                  await setLang();
                }
                clearURLParam();
                resolve();
              }
            },
            (error) => {  
                if(error){
                  clearURLParam();
                  resolve(); 
                }
              }
            );
          })
        },
        nonRegionalLocalization = () => {
          return new Promise<void>(async (resolve, reject) => {

            // proceed only if is clean hostname
            // proceed only if not login
            const user_token = localStorage.getItem('user_token');
            // const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has('lang')) {
              var countryLanguage = urlParams.get('lang').split('_');
              localStorage.setItem('country_code', countryLanguage[0]);
              localStorage.setItem('language_code', countryLanguage[1]);
            }

            if (urlParams.get('lang') || urlParams.get('userToken') || user_token) {
              // await getEventList();
              resolve();
              return;
            }

            // const nonRegionalDomains = dropdownHttpService['nonRegionalDomains'] || [];
            // const hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
            // const rootDomain = hostname ? hostname.split('.').slice(-2).join('.') : null;
            const hostname = window.location.hostname;
            // proceed only if hostname is a non-regional domains
            if (!nonRegionalDomains.includes(hostname)) {
              // await getEventList();
              resolve();
              return;
            }

            // get last access
            const referrerHostname = document.referrer ? new URL(document.referrer).hostname : null;
            // mobile.bp77sgp.com -> bp77sgp.com
            const referrerRootDomain = referrerHostname ? referrerHostname.split('.').slice(-2).join('.') : null;

            let siteDomains = [];

            // gather all main domain into an array
            siteDomains = siteDomains.concat(
              environment.domainName.split('|'),
              environment.altDomainName.split('|'),
              environment.altDomainNameTwo.split('|'),
            );

            // gather all site domain into an array
            Object.values(availableCountryAndLanguages).forEach(countryitems => {
              Object.values(countryitems).forEach(items => {
                siteDomains = siteDomains.concat(items[environment.domainEnv]);
              });
            });

            // mark doamin as other site, as bp9yyds.com will auto redirect to bp9yyds1.com
            siteDomains = siteDomains.filter(item => !['bp9yyds.com'].includes(item));

            // proceed only on first glance where referrer not found within site
            if (siteDomains.includes(referrerRootDomain)) {
              // await getEventList();
              resolve();
              return;
            }

            const localize = (country_code: string) => {
              // check if is supported country code
              // check if content display match current country
              if (country_code != localStorage.getItem('country_code') && country_code in availableCountryAndLanguages[environment.domainName]) {
                let lang_code = 'EN';
                // special cater for following country
                if (country_code == 'TH') {
                  lang_code = 'TH';
                } else if (country_code == 'ID') {
                  lang_code = 'ID';
                }

                localStorage.setItem('language_code', lang_code);
                localStorage.setItem('country_code', country_code);
              }
            }

            const ipAddressPromise = new Promise<void>((ipResolve, ipReject) => {
              locationHttpService.getIpAddress().subscribe(res => {
                if (res['country_code']) {
                  if (environment.domainEnv == 'qa') {
                    // for checking purpose
                    console.log('Localization test: ', res);
                  }

                  localize(res['country_code'].toUpperCase());
                  ipResolve();
                } else {
                  const xhr = new XMLHttpRequest();
                  const url = `https://geolocation-db.com/json/${res['ip']}`;
                  xhr.open('GET', url);
                  xhr.send();
                  xhr.onloadend = () => {
                    if (xhr.response) {
                      const address = JSON.parse(xhr.response);
                      localize(address.country_code.toUpperCase());
                    }

                    // resolve upon xhr end loading
                    ipResolve();
                  }
                }
              });
            });

            if (window.mode != 'Members Only') {
              await ipAddressPromise;
            }

            // await getEventList();
            resolve();
          })
        };
      if (await checkDeviceType()) {
        await setUserBrowser();
        await getBrowserLocale();
        await setFingerprint();
        await getSiteDomain()
        await nonRegionalLocalization();
        await getEventList();
        resolve();
      }
    })
  }
}

// export const EnvServiceFactory = () => {
//   // Create env
//   const env = new EnvService();
//   // Read environment variables from browser window
//   const browserWindow = window || {};
//   const browserWindowEnv = browserWindow['__env'] || {};
//   // Assign environment variables from browser window to env
//   for (const key in browserWindowEnv) {
//     if (browserWindowEnv.hasOwnProperty(key)) {
//       env[key] = window['__env'][key];
//     }
//   }
//   return env;
// };

@NgModule({
  declarations: [
    AppComponent,
    PreLoaderComponent,
  ],
  imports: [
    HammerModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreModule.forFeature('auth', authReducer),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      parser: { provide: TranslateParser, useClass: CustomParser },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
      useDefaultLang: false
      // defaultLanguage: 'en'
    }),
    SharedModule.forRoot(),
    ViewsModule,
    LivechatWidgetModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    RecaptchaV3Module,
    ConnectionServiceModule,
    QRCodeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
  ],
  providers: [
    // { provide: EnvService, useFactory: EnvServiceFactory, deps: [] },
    { provide: APP_INITIALIZER, useFactory: preloadFunction, deps: [DeviceDetectorService, SiteDomainService, DropdownHttpService, LocationHttpService, PredictionDataService], multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    DatePipe,
    DynamicFooterService,
    CookieService,
    FileCompressionPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
