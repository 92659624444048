<div class="modal show">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal1">
            <div class="modal-content modal-bg app-modal">
                <div class="d-flex justify-content-between">
                    <div class="fancy-title mb-0">
                      <h3>{{ gameProvider.game_provider_name }}</h3>
                    </div>
                    <button type="button" class="close" (click)="onCloseDialog()">×</button>                
                </div>
                <div>
                    <hr class="border-hr">
                    <p>{{ 'Please download [providerName] to play.' | translate : { providerName: gameProvider.game_provider_name } }}</p>
                    <div class="download-input-with-icon">
                        <p class="m-b-10">{{ 'Username' | translate | titlecase }}</p>
                        <input type="text" [value]="appDetails.username" class="form-control form-control-sm form-input-style mt-1 position-relative" disabled="" />
                            <a class="btn-copy" [cdkCopyToClipboard]="appDetails.username"
                            (cdkCopyToClipboardCopied)="onCopyText('username')">
                            <span [innerHTML]="svg.copyIcon | safeHtml" alt="Copy Icon" class="copy-icon"
                            (mouseenter)="$event.stopImmediatePropagation()"
                            (mouseleave)="$event.stopImmediatePropagation()" mat-raised-button
                            #tooltipUsername="matTooltip" [matTooltip]="'Copied' | translate" matTooltipPosition="above"></span>
                        </a>
                    </div>
                    <div class="download-input-with-icon">
                        <p class="m-b-10">{{ 'Password' | translate | titlecase }}</p>
                        <input type="text" [value]="appDetails.password" class="form-control form-control-sm form-input-style mt-1 position-relative" disabled="" />
                            <a class="btn-copy" [cdkCopyToClipboard]="appDetails.password"
                            (cdkCopyToClipboardCopied)="onCopyText('password')">
                            <span [innerHTML]="svg.copyIcon | safeHtml" alt="Copy Icon" class="copy-icon"
                            (mouseenter)="$event.stopImmediatePropagation()"
                            (mouseleave)="$event.stopImmediatePropagation()" mat-raised-button
                            #tooltipPassword="matTooltip" [matTooltip]="'Copied' | translate" matTooltipPosition="above"></span>
                        </a>
                    </div>
                    <div class="d-flex justify-content-around">
                        <button type="button" class="btn-blue-gra w-50 mr-1 bottom-action-btn" [disabled]="gameProvider.available === 0 || gameProvider.maintenance === 1">
                            <a class="download-color" [href]="appDetails.url">
                                {{ 'Download' | translate | uppercase }}
                            </a></button>
                        <button type="button" class="btn-yellow-gra w-50 ml-1 bottom-action-btn" (click)="onOpenDeepLink()">{{ 'Play Now' | translate | uppercase }}</button>
                    </div>   
                </div>       
            </div>
        </div>
    </div>
</div>