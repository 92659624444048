<div class="modal show sign-in">
    <div class="modal-dialog-centered swal2-show swal2-modal">
        <div class="modal-body swal2-modal swal2-show">
            <div class="modal-bg modal-maintenance">
                <img src="/assets/images/dpc/maintenance-logo.png" alt="Maintenance Icon" class=" maintenance-icon m-b-20">
                <p> {{data.name}} {{'is undergoing maintenance' | translate}} <br>
                    <app-count-down *ngIf="data.time !== '' " [dateEnd]="data.dateEnd" [contentHash]="contentHash">
                    </app-count-down>
                </p>
                <div class="m-t-20">
                    <button class="maintenance-confirm" (click)="onCloseDialog()">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>