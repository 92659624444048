export const environment = {
  production: false,
  apiBase: "https://api.jk188.net/api/member",
  socketHost: "https://api.jk188.net",
  translationDomain: "https://e86186d6.quickcdn.org",
  luckySpinDomain: "https://luckyspin.jk188.net",
  sitePrefix: "bp77",
  domainEnv: "staging", // production | demo | staging | local : this value is to decide which domain list to use under dropdown-http.service.ts
  https: true, // true use "https://", false use "//"
  domainName: "jk188.net",
  mobileDomainName: "m2.jk188.net",
  altDomainName: "",
  altMobileDomainName: "",
  altDomainNameTwo: "",
  altMobileDomainNameTwo: "",
  forceMaintenance: false,
  shutdown: false,
  recaptcha: {
    siteKey: "6LfeX5ggAAAAALY8xH4oc7RIzL_J-svVRmtcdQV6",
    secretKey: "6LfeX5ggAAAAAEQIgT9F1Du9RM5tnM8zVjKxPpVg",
  },
  sentry_dsn: "",
  reqSignKey: "kbXbAEotZ64nueRXt0+fWKBndGADLrQiaL6VrhM+mSw=",
  firebaseConfig: {
    apiKey: "AIzaSyDVVeVC71pnWBmCOdt_DrSECf4U1RSfWDM",
    authDomain: "qpro1-my.firebaseapp.com",
    projectId: "qpro1-my",
    storageBucket: "qpro1-my.appspot.com",
    messagingSenderId: "41870026605",
    appId: "1:41870026605:web:951ac6039d8f53ccc6dc64",
    measurementId: "G-5618FPRGYT",

    // Web Push Certificate specifically for FCM
    vapidKey: "BHY5VFFQuzFEM7q8BK83wdMamDxfs2YLQ9G-SaGnZIu1KZaOJLxic2d-j87NfB8q8oeljQyuL1KaU5KoObtG9w0"
  },
};