<div class="modal show" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content popup-bg">
      <div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="onCloseDialog()"
        >
          &times;
        </button>
      </div>

      <div class="modal-body mt-4 p-3">
        <swiper
          class="swiper-popup"
          [config]="config"
          *ngIf="data.contents.length > 0"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              *ngFor="let item of data.contents; let i = index"
            >
              <p class="w-100">
                {{ item["content"] }}
              </p>
            </div>
          </div>

          <div class="swiper-pagination-popup"></div>
        </swiper>
      </div>
    </div>
  </div>
</div>
