import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AesService } from './aes.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
  currentToken = new BehaviorSubject<string>(null);

  constructor(
    private afMessaging: AngularFireMessaging,
    private http: HttpClient,
    private aesService: AesService,
  ) {}

  async requestPermission() {
    try {
      if (!('serviceWorker' in navigator)) {
        throw new Error('Service Worker is not supported');
      }

      // // Register service worker first (to prevent worker not found on initial stage)
      await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      await navigator.serviceWorker.ready;

      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        try {
          const token = await this.getToken();

          if (token) {
            const member_account_id = JSON.parse(localStorage.getItem('user_data')).id;
            const encryptedToken = this.aesService.encryptWithReqSignKey(token);

            await this.saveTokenToBackend({
              member_account_id,
              token: encryptedToken,
            });
          }
        } catch (tokenError) {
          console.error('Error getting token:', tokenError);
        }
      }
    } catch (err) {
      console.error('Failed to get permission:', err);
    }
  }

  private getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afMessaging.requestToken
        .pipe(take(1))
        .subscribe(
          (token) => {
            if (token) {
              resolve(token);
            } else {
              reject('No token received');
            }
          },
          (error) => {
            console.error('Error getting token:', error);
            reject(error);
          }
        );
    });
  }

  private saveTokenToBackend(data = {}) {
    return this.http.post('/fcm/token', data).toPromise();
  }

  listenToMessages() {
    return this.afMessaging.messages;
  }
}