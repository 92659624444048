import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MemberBankHttpService } from '@core/services/member-bank-http.service';
import { catchError, delay, tap } from 'rxjs/operators';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { MemberDepositWithdrawLastOptionHttpService } from '@core/services/member-deposit-withdraw-last-option-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { BankWrapperModalComponent } from '@shared/bank-wrapper-modal/bank-wrapper-modal.component';
import { of, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { WithdrawVerificationModalComponent } from '../../dialogs/withdraw-verification-modal/withdraw-verification-modal.component';
import { PortalWithdrawtHttpService } from '../../services/portal-wtihdraw-http.service';
import { BankHttpService } from '@core/services/bank-http.service';
import { BankMaintenanceHourService } from '@core/services/bank-maintenance-hour.service';
import * as moment from 'moment';
import { EwalletVerificationRejectionComponent } from '@shared/ewallet-verification-rejection/ewallet-verification-rejection.component';
import { CryptoWalletHttpService } from '@core/services/crypto-wallet-http.service';
import { CryptoWalletModalComponent } from '../../dialogs/crypto-wallet-modal/crypto-wallet-modal.component';
import svgIconList from 'assets/icons.json';
import { BankTypes } from '@core/enums/bank-type.enum';
import { EwalletVerificationModalComponent } from '@shared/ewallet-verification-modal/ewallet-verification-modal.component';

@Component({
  selector: 'app-portal-withdraw',
  templateUrl: './portal-withdraw.component.html',
  styleUrls: ['./portal-withdraw.component.scss']
})
export class PortalWithdrawComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  @ViewChild('bankMaintenanceHourTemplate') bankMaintenanceHourTemplate: ElementRef;

  showBanks = false;
  showAmountButtons = true;
  showPaymentMethod = false;
  showCryptoWallet = false
  amount: number;
  amountPercentageButton = ['25%', '50%', '75%', 'MAX'];
  amountButton = [];
  amountPercentage = [0.25, 0.50, 0.75, 1];

  userWalletAmount: number;

  memberBanks: any;
  disableButton = false;
  form: FormGroup;
  messages$ = this.portalWithdrawtHttpService.messages$;
  isSuccess = this.portalWithdrawtHttpService.isSuccess;
  withdrawalLimit = {};
  selectedBank: any = null;
  existing_bank_name = [];
  announcements$ = [];
  announcementIds = [];
  countryCode: string;
  checkValidity = false;
  currencyCode = '$';

  IDRAmount: string;
  windowInnerHeight: number;
  memberBankAccounts = JSON.parse(sessionStorage.getItem('member_bank_accounts'));
  memberCryptoWallets = sessionStorage.getItem('member_crypto_accounts') != null ? JSON.parse(sessionStorage.getItem('member_crypto_accounts')) : [];
  payNowNumber: number;

  bankMaintenanceHour: any = [];
  gmt: any;
  currentTime: any;
  currentDayOfWeek: any;
  currentDate: any;
  bank_ids = [];
  yesterdayTime: any;
  yesterday: any;
  yesterdayDate: any;

  itemsDisplay = false;

  allBank = JSON.parse(sessionStorage.getItem('all_bank'));

  paymentMethod = [
    { id: 1, name: 'Bank' },
    { id: 3, name: 'E-Wallet' },
    { id: 6, name: 'Crypto' }
  ];
  selectedMethodName: any;
  selectedMethodId: any;
  selectedBankType: any = 'Bank';
  selectedCryptoWallet = null;
  isDialogOpen = false;
  cryptoExchangeRate = [];

  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();

  constructor(
    private dropdownService: DropdownHttpService,
    private portalWithdrawtHttpService: PortalWithdrawtHttpService,
    public loadingBar: LoadingBarService,
    private router: Router,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private memberBankHttpService: MemberBankHttpService,
    private translateService: TranslateService,
    private walletHttpService: WalletHttpService,
    private withdrawLastOptionService: MemberDepositWithdrawLastOptionHttpService,
    private bankHttpService: BankHttpService,
    private bankMaintenanceHourService: BankMaintenanceHourService,
    private cryptoWalletHttpService: CryptoWalletHttpService,
  ) { }

  async ngOnInit() {
    this.windowInnerHeight = window.innerHeight;

    if (JSON.parse(localStorage.getItem('user_data')).currency !== null) {
      this.currencyCode = JSON.parse(localStorage.getItem('user_data')).currency.code;
      this.countryCode = localStorage.getItem('country_code');
    }
    this.formInit();
    this.getMemberBankAccount();
    this.getCryptoWallets();
    this.eventEmitterService.reloadBankListEmitter.subscribe((name: string) => {
      this.memberBankHttpService.getMemberBanks().subscribe((res) => {
        this.memberBankAccounts = res;
        localStorage.setItem('member_bank_accounts', this.memberBankAccounts);
        sessionStorage.setItem('member_bank_accounts', JSON.stringify(this.memberBankAccounts));
        
        let filteredMemberBank = [];
        this.existing_bank_name = [];

        if (this.memberBankAccounts) {
          Object.keys(this.memberBankAccounts).forEach(key1 => {
            if (this.memberBankAccounts[key1]['bank_code'] == null) {
              this.existing_bank_name.push(this.memberBankAccounts[key1]['bank_name']);
              this.memberBankAccounts[key1]['showBank'] = true;
              filteredMemberBank.push(this.memberBankAccounts[key1]);
            }
            else {
              Object.keys(this.allBank).forEach(key2 => {
                if (this.memberBankAccounts[key1]['bank_code'] == this.allBank[key2]['code']) {
                  this.existing_bank_name.push(this.memberBankAccounts[key1]['bank_name']);
                  this.memberBankAccounts[key1]['showBank'] = this.allBank[key2]['showBank'];
                  filteredMemberBank.push(this.memberBankAccounts[key1]);
                }
              })
            }
          });

          this.memberBankAccounts = filteredMemberBank;
          this.memberBanks = this.memberBankAccounts.filter(x => x.bank_type == this.selectedMethodId);
          this.setPayNowNumber(this.memberBankAccounts);
        }

        this.onCloseDialog();
      });
    });
    this.eventEmitterService.openVerificationEmitter.subscribe((name: string) => {
      this.openDialogBy(WithdrawVerificationModalComponent, null, null, JSON.parse(localStorage.getItem('user_data')).otp_status);

    });
    this.checkVerification();
    this.eventEmitterService.buttonEmitter.subscribe(() => {
      this.disableButton = false;
    });
    this.eventEmitterService.swalAnnouncementVar = this.eventEmitterService.swalAnnouncementEmitter.subscribe(page => {
      if (page === 'withdraw') {
        this.onSwalFire();
      }
    });
    this.onSwalFire();
    this.userWalletAmount = this.walletHttpService.memberBalance?.value.balance;

    if (this.allBank == null || this.allBank.length == 0) {
      this.bankHttpService.getAllBanks().subscribe(async res => {
        this.allBank = res;
        sessionStorage.setItem('all_bank', JSON.stringify(res))

        await this.assignMemberBank();
      });
    } else {
      await this.assignMemberBank();
    }

    //not displaying e-wallet payment method when there is no active e-wallet
    let hasEWallet = this.allBank && this.allBank.some(bank => bank.bank_type === BankTypes.EWALLET);
    if (!hasEWallet) {
      this.paymentMethod = this.paymentMethod.filter(method => method.id !== BankTypes.EWALLET);
    }

    //not displaying crypto payment method when there is no active crypto token
    let hasCrypto = this.allBank && this.allBank.some(bank => bank.bank_type === BankTypes.CRYPTO);
    if (!hasCrypto) {
      this.paymentMethod = this.paymentMethod.filter(method => method.id !== BankTypes.CRYPTO);
    }

    //display last option for user
    this.withdrawLastOptionService.getLastOption(2).subscribe((lastOption) => {
      //if last option exist
      if (lastOption && hasEWallet) {
        let indexOfLastMemberBankOption = this.memberBanks.findIndex(item => item.id === lastOption.member_bank_account_id)

        if (indexOfLastMemberBankOption !== -1) {
          if (this.memberBanks[indexOfLastMemberBankOption].bank_type == BankTypes.BANK) {
            this.onSelectPaymentMethod({ id: BankTypes.BANK, name: 'Bank' }, true);
          }
          else {
            this.onSelectPaymentMethod({ id: BankTypes.EWALLET, name: 'E-Wallet' }, true);
          }
          this.onSelectBank(this.memberBanks[indexOfLastMemberBankOption]);
          this.memberBanks = this.memberBankAccounts.filter(x => x.bank_type == this.memberBanks[indexOfLastMemberBankOption].bank_type);
        }
        else {
          this.onSelectPaymentMethod({ id: BankTypes.BANK, name: 'Bank' });
        }
      }
      else {
        this.onSelectPaymentMethod({ id: BankTypes.BANK, name: 'Bank' });
      }

      // Get and set withdrawal limit
      this.checkLimit();
    })
  }

  // await this function to finish first then only set the bank dropdown to avoid unfilter bank dropdown bug
  assignMemberBank(): Promise<void> {
    return new Promise((resolve) => {
      JSON.parse(sessionStorage.getItem('all_bank')).forEach(merchant_banks => {
        if (merchant_banks['id']) {
          this.bank_ids.push(merchant_banks['id']);
        }
      });

      this.bankMaintenanceHourService.getBankMaintenanceHours(this.bank_ids).subscribe(res => {
        localStorage.setItem('bank_maintenance_hour', JSON.stringify(res));
        this.formatBankMaintenanceHour(JSON.parse(localStorage.getItem('bank_maintenance_hour'))['rows']);

        let filteredMemberBank = [];

        // Update bank maintenance hours
        this.allBank.forEach(item => {
          let maintenanceHour = this.bankMaintenanceHour.find(x => x.bank_id == item.id);
          this.gmt = '';
          if (maintenanceHour != undefined) {
            item['enable_all_day'] = maintenanceHour['enable_all_day'];
            item['enable_monday'] = maintenanceHour['enable_monday'];
            item['enable_tuesday'] = maintenanceHour['enable_tuesday'];
            item['enable_wednesday'] = maintenanceHour['enable_wednesday'];
            item['enable_thursday'] = maintenanceHour['enable_thursday'];
            item['enable_friday'] = maintenanceHour['enable_friday'];
            item['enable_saturday'] = maintenanceHour['enable_saturday'];
            item['enable_sunday'] = maintenanceHour['enable_sunday'];
            item['all_day'] = maintenanceHour['all_day'];
            item['monday'] = maintenanceHour['monday'];
            item['tuesday'] = maintenanceHour['tuesday'];
            item['wednesday'] = maintenanceHour['wednesday'];
            item['thursday'] = maintenanceHour['thursday'];
            item['friday'] = maintenanceHour['friday'];
            item['saturday'] = maintenanceHour['saturday'];
            item['sunday'] = maintenanceHour['sunday'];
            item['display'] = maintenanceHour['display'];
            item['availability_control'] = maintenanceHour['availability_control'];

            this.gmt = maintenanceHour['gmt'];
          }

          this.isBankMaintenance(item, this.gmt);
        });

        sessionStorage.setItem('all_bank', JSON.stringify(this.allBank));

        if (this.memberBankAccounts) {
          Object.keys(this.memberBankAccounts).forEach(key1 => {
            if (this.memberBankAccounts[key1]['bank_code'] == null) {
              this.memberBankAccounts[key1]['showBank'] = true;
              filteredMemberBank.push(this.memberBankAccounts[key1]);
            }
            else {
              Object.keys(this.allBank).forEach(key2 => {
                if (this.memberBankAccounts[key1]['bank_code'] == this.allBank[key2]['code']) {
                  this.memberBankAccounts[key1]['showBank'] = this.allBank[key2]['showBank'];
                  filteredMemberBank.push(this.memberBankAccounts[key1]);
                }
              })
            }
          });

          this.memberBankAccounts = filteredMemberBank;
          this.memberBanks = this.memberBankAccounts;
        }
        resolve();
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
  }

  onSwalFire() {
    if (localStorage.getItem('announcements_pop') !== null) {
      localStorage.removeItem('popup_ids');
      JSON.parse(localStorage.getItem('announcements_pop')).map(announcement => {
        if ((announcement.location_name === 'all' || announcement.location_name === 'withdraw') && announcement.seen === 0) {
          this.announcements$.push(announcement.content);
          this.announcementIds.push(announcement.id);
        }
      });
      localStorage.setItem('popup_ids', JSON.stringify(this.announcementIds));
    }
  }

  onUpdateAnnouncement() {
    let isSeen = 0;
    const announcementPop = [];

    JSON.parse(localStorage.getItem('announcements_pop')).forEach(announcement => {
      this.announcementIds.includes(announcement.id) ? isSeen = 1 : isSeen = 0;

      announcementPop.push({
        ...announcement,
        seen: announcement.seen == 1 ? 1 : isSeen,
      });
    });

    localStorage.setItem('announcements_pop', JSON.stringify(announcementPop));
  }

  onAddBank(bank_type: any) {
    this.openDialogBy(BankWrapperModalComponent, this.existing_bank_name, bank_type, null, null, null, null);
  }

  onCloseDialog() {
    this.formInit();
  }

  onSave() {
    if (localStorage.getItem('verified_number') === 'false') {
      this.openDialogBy(WithdrawVerificationModalComponent, null, null, JSON.parse(localStorage.getItem('user_data')).otp_status);
    } else {
      this.checkValidity = true;
      if (this.form.valid) {
        this.disableButton = true;
        this.loadingBar.start();
        const data = {
          ...this.form.value
        };
        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        data.account_type = this.selectedMethodName != "Crypto" ? 1 : 2;
        this.portalWithdrawtHttpService.add(data).pipe(
          catchError(e => {
            this.disableButton = false;
            this.loadingBar.complete();
            this.checkValidity = false;
            throw e;
          })
        ).subscribe(() => {
          this.disableButton = false;
          this.loadingBar.complete();
          this.disableButton = false;
          this.checkValidity = false;
          this.isSuccess = this.portalWithdrawtHttpService.isSuccess;
          if (this.isSuccess) {
            setTimeout(() => {
              this.router.navigate(['/member/history/transaction']);
            });
            this.walletHttpService.getMemberBalance().subscribe();
          }
        });
      }
    }
  }

  private getMemberBankAccount() {
    if (this.memberBankAccounts === null) {
      this.dropdownService.memberBanks.subscribe(res => {
        sessionStorage.setItem('member_bank_accounts', JSON.stringify(res));
        this.memberBankAccounts = res;
        this.setDropDown(res);
      });
    } else {
      this.setDropDown(this.memberBankAccounts);
    }
  }

  private getCryptoWallets() {
    this.cryptoWalletHttpService.getAll().pipe(
      tap(res => {
        this.memberCryptoWallets = res;
      })
    ).subscribe();
  }

  private setDropDown(bankAccounts: any) {
    if (bankAccounts.length === 0 && this.memberCryptoWallets.length === 0 && localStorage.getItem('verified_number') !== 'false') {
      Swal.fire({
        html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
          '<div class="text-center m-t-20">' +
          '<ul><li>' + this.translateService.instant('Please add bank account') + '</li></ul>' +
          '</div>',
        confirmButtonText: 'OK',
      }).then(result => {
        if (result.value) {
          this.dialog.open(BankWrapperModalComponent, {
            width: 'auto',
            data: {
              is_withdraw: true
            }
          });
        } else {
          this.router.navigate(['/member/deposit']);
        }
      });
    }
    bankAccounts.forEach((item) => {
      this.existing_bank_name.push(item.bank_name);
    })
    this.memberBanks = bankAccounts;
    this.setPayNowNumber(bankAccounts);

  }

  private checkVerification() {
    if (localStorage.getItem('verified_number') === 'false') {
      this.openDialogBy(WithdrawVerificationModalComponent, null, null, JSON.parse(localStorage.getItem('user_data')).otp_status);
    }
  }

  private openDialogBy(componentRef: any, existing_bank_name?: any, bank_type?: any, can_request_otp?: boolean, onDialog?: boolean, verification?: boolean, verificationDetails?: any, reupload?: boolean) {
    if (verification == true) {
      var dialogRef = this.dialog.open(componentRef, {
        width: 'auto',
        data: {
          reupload: reupload,
          bankAccountId: verificationDetails.id,
          bank_name: verificationDetails.bank_name,
          account_number: verificationDetails.account_number,
          reason: verificationDetails.reason,
          verificationSetting: verificationDetails,
          bankVerificationStatus: verificationDetails.status
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.eventEmitterService.onUpdateBankList();
          this.eventEmitterService.onReloadBankList();
          this.eventEmitterService.onUpdateVerificationEmitter();
        }
        this.onCloseDialog();
      });
    } else {
      this.isDialogOpen = true;
      return new Promise<boolean>((resolve, reject) => {
        const dialogRef = this.dialog.open(componentRef, {
          width: '800px',
          data: {
            existing_bank_name: existing_bank_name,
            can_request_otp: can_request_otp,
            bank_type: bank_type,
            onDialog: onDialog
          }
        });
  
        dialogRef.afterClosed().subscribe((result) => {
          if (result === true) {
            this.isDialogOpen = false;
            resolve(true);
          } else {
            this.isDialogOpen = false;
            resolve(false);
          }
        });
      });
    }
  }

  private formInit() {
    this.form = new FormGroup({
      bank_account_id: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, Validators.compose([Validators.required, Validators.min(0.01)]))
    });
  }

  onSelectBank(data: any) {
    if (data.showBank != false) {
      this.form.patchValue({
        bank_account_id: data.id,
        amount: null,
      });
      this.selectedBank = data;
      this.showBanks = false;
    }
  }

  onShowBanks() {
    this.showBanks = true;
    this.showPaymentMethod = false;
  }

  onToggleAmountButtons(show: boolean, timeout = 0) {
    setTimeout(() => {
      if (this.countryCode.toUpperCase() === 'ID' && show) {
        if (this.form.value.amount !== null) {
          this.IDRAmount = (+this.form.value.amount).toString();
        }
      }
      else if (this.countryCode.toUpperCase() === 'ID' && !show) {
        if (this.IDRAmount !== undefined) {
          this.form.patchValue({
            amount: (+this.IDRAmount)
          })
          this.IDRAmount = `${this.IDRAmount}`;
        }
      }
      //this.showAmountButtons = show;
      if (this.showAmountButtons && !this.isDialogOpen) {
        of(null).pipe(
          delay(0), tap(() => this.focusInput.first.nativeElement.focus()
          )).subscribe();
      }
    }, timeout)
  }


  onSelectAmount(selectedAmount: number | string, i: number) {
    document.getElementById('withdrawal-amount-0').style.background = '';
    document.getElementById('withdrawal-amount-1').style.background = '';
    document.getElementById('withdrawal-amount-2').style.background = '';
    document.getElementById('withdrawal-amount-3').style.background = '';
    if (selectedAmount === 'MAX') {
      if (this.countryCode.toUpperCase() === 'ID') {
        this.IDRAmount = (this.userWalletAmount).toString();
      }
      this.form.patchValue({ amount: this.userWalletAmount });
    } else {
      if (this.countryCode.toUpperCase() === 'ID') {
        this.IDRAmount = selectedAmount.toString();
      }
      this.form.patchValue({ amount: selectedAmount });
    }
    document.getElementById('withdrawal-amount-' + i).style.background = 'var(--mainColor1)';
  }

  // ONLY IDR WILL TRIGGER THIS FUNCTION
  onInputAmount() {
    this.IDRAmount = this.IDRAmount ? this.IDRAmount : undefined;
    this.form.patchValue({ amount: this.IDRAmount ? +this.IDRAmount : null });
  }

  @HostListener('window:resize', [])
  onResize() {
    if (window.innerHeight < this.windowInnerHeight) {
      window.scroll(0, 125);
    }
  }

  private setPayNowNumber(data: any) {
    if (data) {
      data.map(res => {
        if (res.bank_name.toLowerCase() === 'paynow') {
          if (res.virtual_payment_address !== '') {
            this.payNowNumber = res.virtual_payment_address;
          }
          if (res.unique_entity_name !== '') {
            this.payNowNumber = res.unique_entity_name;
          }
          if (res.nric_fin !== '') {
            this.payNowNumber = res.nric_fin;
          }
          if (res.mobile_number !== '') {
            this.payNowNumber = res.mobile_number;
          }
        }
      });
    }
  }

  onBankMaintenanceHours(event: any) {
    const nativeElement = this.bankMaintenanceHourTemplate.nativeElement;
    nativeElement.style.cssText = 'visibility:visible';

    Swal.fire({
      title: this.translateService.instant('Bank Maintenance Hours'),
      html: this.bankMaintenanceHourTemplate.nativeElement,
      confirmButtonText: this.translateService.instant('Close'),
      showCloseButton: true,
      customClass: {
        title: 'bank-maintenance-hour-title',
        container: 'bank-maintenance-hour-container',
        popup: 'bank-maintenance-hour-popup',
      }
    });
  }

  isBankMaintenance(item: any, gmt: any) {
    item['showBank'] = true;

    if (gmt != '' && (item['availability_control'] == 2 || item['availability_control'] == 3)) {
      let hours_minutes = gmt.split(':');
      let posOrNeg = (hours_minutes[0][0] == '-') ? '-' : '+'; // get positive or negative
      let hours = hours_minutes[0];
      let minutes = hours_minutes[1];

      // get the user current time
      this.currentTime = moment.utc().add(parseInt(hours), 'hours').add(parseInt(posOrNeg + minutes), 'minutes');
      this.currentTime = moment(this.currentTime.format('HH:mm'), 'HH:mm');
      this.currentDayOfWeek = this.currentTime.clone().locale('en').format('dddd').toLowerCase();
      this.currentDate = this.currentTime.clone().locale('en').format('YYYY-MM-DD').toLowerCase();

      // get the user yesterday time
      this.yesterdayTime = moment.utc().add(parseInt(hours), 'hours').add(parseInt(posOrNeg + minutes), 'minutes');
      this.yesterdayTime = moment(this.yesterdayTime.format('HH:mm'), 'HH:mm').subtract(1, "days");
      this.yesterday = this.yesterdayTime.clone().locale('en').format('dddd').toLowerCase();
      this.yesterdayDate = this.yesterdayTime.clone().locale('en').format('YYYY-MM-DD').toLowerCase();

      // if enable_all_day is on
      if (item['enable_all_day'] == true) {
        for (let i = 0; i < item['all_day'].length; i++) {
          // get the maintenance hour time (from & to)
          let all_day = item['all_day'][i];

          // since all day, check yesterday overlap case
          // eg: today is 2024-02-02 00:02
          // it compare 2024-02-01 23:00 -> 2024-02-02 06:00
          let yesterday_from = moment(moment.utc(`${this.yesterdayDate} ${all_day.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let yesterday_to = moment(moment.utc(`${this.yesterdayDate} ${all_day.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if (yesterday_to < yesterday_from) {
            yesterday_to = yesterday_to.add(1, 'days');
          }

          console.log(yesterday_from.toString())
          console.log(yesterday_to.toString());

          // check if the current bank is in maintenance
          // check if the current bank is in maintenance
          if (this.currentTime.isBetween(yesterday_from, yesterday_to) || this.currentTime.isSame(yesterday_from) || this.currentTime.isSame(yesterday_to)) {
            item['showBank'] = false;
            break;
          }

          // since all day, check today overlap case
          // eg: today is 2024-02-02 00:02
          // it compare 2024-02-02 23:00 -> 2024-02-03 06:00
          let today_from = moment(moment.utc(`${this.currentDate} ${all_day.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let today_to = moment(moment.utc(`${this.currentDate} ${all_day.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if (today_to < today_from) {
            today_to = today_to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if (this.currentTime.isBetween(today_from, today_to) || this.currentTime.isSame(today_from) || this.currentTime.isSame(today_to)) {
            item['showBank'] = false;
            break;
          }
        }
      }

      // if yesterday checkbox is enabled
      if (item['enable_' + this.yesterday] == true) {
        for (let i = 0; i < item[this.yesterday].length; i++) {
          // get the maintenance hour time (from & to)
          let dayOfWeek = item[this.yesterday][i];

          let from = moment(moment.utc(`${this.yesterdayDate} ${dayOfWeek.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let to = moment(moment.utc(`${this.yesterdayDate} ${dayOfWeek.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if (to < from) {
            to = to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if (this.currentTime.isBetween(from, to) || this.currentTime.isSame(from) || this.currentTime.isSame(to)) {
            item['showBank'] = false;
            break;
          }
        }
      }

      // if today checkbox is enabled
      if (item['showBank'] == true && item['enable_' + this.currentDayOfWeek] == true) {
        for (let i = 0; i < item[this.currentDayOfWeek].length; i++) {
          // get the maintenance hour time (from & to)
          let dayOfWeek = item[this.currentDayOfWeek][i];

          let from = moment(moment.utc(`${this.currentDate} ${dayOfWeek.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let to = moment(moment.utc(`${this.currentDate} ${dayOfWeek.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if (to < from) {
            to = to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if (this.currentTime.isBetween(from, to) || this.currentTime.isSame(from) || this.currentTime.isSame(to)) {
            item['showBank'] = false;
            break;
          }
        }
      }
    }
  }

  // group day together if same maintenance hours
  formatBankMaintenanceHour(bankMaintenanceHour) {
    bankMaintenanceHour.forEach(x => {
      let result = {
        bank_id: x.bank_id,
        bank_name: x.bank_name,
        bank_code: x.bank_code,
        desktop_image: x.desktop_image,
        mobile_image: x.mobile_image,
        enable_all_day: x.enable_all_day,
        enable_monday: x.enable_monday,
        enable_tuesday: x.enable_tuesday,
        enable_wednesday: x.enable_wednesday,
        enable_thursday: x.enable_thursday,
        enable_friday: x.enable_friday,
        enable_saturday: x.enable_saturday,
        enable_sunday: x.enable_sunday,
        all_day: x.all_day,
        monday: x.monday,
        tuesday: x.tuesday,
        wednesday: x.wednesday,
        thursday: x.thursday,
        friday: x.friday,
        saturday: x.saturday,
        sunday: x.sunday,
        other_day: [],
        gmt: x.gmt,
        display: x.display,
        availability_control: x.availability_control
      }

      if ((x.display == 2 || x.display == 3) && this.itemsDisplay == false) {
        this.itemsDisplay = true;
      }

      if (x.enable_all_day) {
        this.bankMaintenanceHour.push(result);
      } else {
        let other_day = [];
        if (x.enable_monday) {
          other_day.push({ day: 1, time: x.monday });
        }
        if (x.enable_tuesday) {
          other_day.push({ day: 2, time: x.tuesday });
        }
        if (x.enable_wednesday) {
          other_day.push({ day: 3, time: x.wednesday });
        }
        if (x.enable_thursday) {
          other_day.push({ day: 4, time: x.thursday });
        }
        if (x.enable_friday) {
          other_day.push({ day: 5, time: x.friday });
        }
        if (x.enable_saturday) {
          other_day.push({ day: 6, time: x.saturday });
        }
        if (x.enable_sunday) {
          other_day.push({ day: 7, time: x.sunday });
        }

        other_day = other_day.sort((a, b) => a.day - b.day);

        let last;
        let lastTime;
        let time = [];
        let continuousDays = [];
        let continuousDay = [];

        other_day.forEach((x, i) => {
          if (i == 0) {
            continuousDay.push(x.day);
            time.push(x.time);
          } else {
            if (x.day - last == 1 && JSON.stringify(x.time) === JSON.stringify(lastTime)) {
              continuousDay.push(x.day);
              if (i == other_day.length - 1) {
                continuousDays.push(continuousDay);
                time.push(x.time);
              }
            } else {
              continuousDays.push(continuousDay);
              continuousDay = [x.day];
              time.push(x.time);
            }
          }
          last = x.day;
          lastTime = x.time;
        });

        if (continuousDays.length) {
          continuousDays.forEach((continuousDay, i) => {
            if (continuousDay.length > 1) {
              let startIndex = other_day.map(x => x.day).indexOf(continuousDay[0]);
              let length = continuousDay.length;
              other_day.splice(startIndex, length, { startDay: continuousDay[0], endDay: continuousDay[length - 1], time: time[i] });
            }
          });
        }

        result.other_day = other_day;
        this.bankMaintenanceHour.push(result);
      }

    })
  }

  getDay(day) {
    switch (day) {
      case 1: return "Mon";
      case 2: return "Tue";
      case 3: return "Wed";
      case 4: return "Thu";
      case 5: return "Fri";
      case 6: return "Sat";
      case 7: return "Sun";
      default: return;
    }
  }

  onSelectPaymentMethod(value: any, lastOption?: boolean) {
    const bank_type = value.id;

    if (lastOption != true) {
      this.memberBanks = this.memberBankAccounts.filter(x => x.bank_type == bank_type);
    }
    this.selectedMethodName = value.name;
    this.selectedMethodId = bank_type;

    if (bank_type == BankTypes.BANK) {
      this.selectedBankType = 'Bank';
    }
    else if (bank_type == BankTypes.EWALLET) {
      this.selectedBankType = 'E-Wallet';
    } else if (bank_type == BankTypes.CRYPTO) {
      this.selectedBankType = 'Crypto Wallet'
    }

    this.selectedBank = null;
    this.selectedCryptoWallet = null;
    this.showBanks = false;
    this.form.patchValue({
      bank_account_id: null,
      amount: null,
    });
    this.showPaymentMethod = false;
  }

  onSelectCryptoWallets(value) {
    this.selectedCryptoWallet = value;
    this.form.patchValue({
      bank_account_id: value.id,
    });
    this.showCryptoWallet = false;
  }

  onShowPaymentMethod() {
    this.showPaymentMethod = true;
    this.showBanks = this.showCryptoWallet = false;
  }

  onSelect(value?: any) {
    if (value.status != null && value.verification_settings_field.length > 0) {
      if (value.status == 2) {
        this.openDialogBy(EwalletVerificationRejectionComponent, null, null, null, null, true, value);
      } else if (value.status == 4) {
        this.openDialogBy(EwalletVerificationModalComponent, null, null, null, null, true, value, true);
      } else {
        this.onSelectBank(value);
      }
    } else {
      this.onSelectBank(value);
    } 
  }

  onShowCryptoWallets() {
    this.showCryptoWallet = true;
    this.showPaymentMethod = false;
  }

  onAddCryptoWallet() {
    this.openDialogBy(CryptoWalletModalComponent, undefined, undefined, undefined, true).then((result) => {
      if (result === true) {
        this.getCryptoWallets();
      }
    });
  }

  getCryptoCurrencyRate(limit, type = 1): number {
    if (!this.cryptoExchangeRate) {
      return 0;
    }
    const exchangeRateEntry = this.cryptoExchangeRate.find(entry => entry.Token === this.selectedCryptoWallet?.token);
    if (exchangeRateEntry) {
      if (type == 1) {
        return limit / exchangeRateEntry.Estimate_Withdrawal_Credit;
      } else {
        return exchangeRateEntry.Estimate_Withdrawal_Credit;
      }
    }
    return 0;
  }

  showToken(selectedCryptoWallet) {
    const filteredWallets = this.allBank.filter(bank => {
      return bank.bank_type === 6 && bank.name === selectedCryptoWallet.token;
    });

    if (filteredWallets.length > 1) {
      return `${selectedCryptoWallet.token} - ${selectedCryptoWallet.network}`;
    } else if (filteredWallets.length === 1) {
      return selectedCryptoWallet.token;
    } else {
      return "";
    }
  }

  checkLimit() {
    this.portalWithdrawtHttpService.getLimit().subscribe(res => {
      this.withdrawalLimit = res;

      // update amount validator
      const minWithdrawal = +this.withdrawalLimit['min'];
      const maxWithdrawal = +this.withdrawalLimit['max'];

      const validators = [
        Validators.required,
        ...(minWithdrawal !== 0 ? [Validators.min(minWithdrawal)] : []),
        ...(maxWithdrawal !== 0 ? [Validators.max(maxWithdrawal)] : [])
      ];
      this.form.controls['amount'].setValidators(validators);
      this.form.controls['amount'].updateValueAndValidity();

      this.cryptoExchangeRate = res.cr_exchange_rate;
      this.walletHttpService.getMemberBalance().subscribe(res => {
        this.amount = res.balance;

        if( +this.amount >= minWithdrawal ) {
          if( maxWithdrawal > 0 && +this.amount > maxWithdrawal ) {
            this.amount = maxWithdrawal;
          }
          this.form.patchValue({
            amount: +this.amount
          });
        } else {
          this.form.patchValue({
            amount: null
          });
        }
        
        // if (this.withdrawalLimit['max'] > 0 && +this.withdrawalLimit['max'] <= +this.amount) {
        //   this.amount = this.withdrawalLimit['max'];
        // }

        // this.form.patchValue({
        //   ...this.form,
        //   amount: this.amount
        // });
        if (this.amount != 0 && +this.amount > +this.withdrawalLimit['min']) {
          this.amountPercentage.forEach((percentage) => {
            if (Math.round(this.amount * percentage) > +this.amount) {
              this.amountButton.push(this.amount);
            } else {
              this.amountButton.push(Math.round(this.amount * percentage));
            }
          });
        }
        this.amount = res.balance;
      });
    });
  }
}
