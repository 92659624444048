import {
  HttpErrorResponse, HttpHandler,
  HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { fingerprintId } from 'app/app.module';
import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { FingerprintHttpService } from '@core/services/fingerprint-http.service';
import { environment } from '@env/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  languageCode = '';
  countryCode = '';
  deviceType: string;
  sessionIdTracker = '';
  private ngUnsubscribe = new Subject();
  isAuth: string = 'false';

  constructor(
    private store: Store<AppState>,
    private authService: AuthHttpService,
    private fingerprintHttpService: FingerprintHttpService,) {
    window.onbeforeunload = () => {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const apiBase: string = environment.apiBase;
    const isExternal: boolean = request.url.startsWith('//');
    const userToken = localStorage.getItem('user_token');
    const isIpAPI = request.url == '/getip';
    this.sessionIdTracker = localStorage.getItem('sessionid_tracker');
    let marketingCampaign = JSON.parse(localStorage.getItem('marketing_campaign'));

    const {
      access_token,
      plaintext_token,
      expiry_datetime
    }: any = JSON.parse(userToken) || { access_token: undefined, plaintext_token: undefined };

    const {
      userAgent
    }: { userAgent: string }
      = JSON.parse(localStorage.getItem('user_browser')) || { userAgent: undefined, expiry_datetime: Date };

    if (!request.url.includes(environment.translationDomain)) {
      if (access_token && !isExternal) {
        request = request.clone({
          headers: request.headers
            .append('Authorization', `Bearer ${access_token}`)
            .append('access-token', `${access_token}`)
            .append('token-selector', `${plaintext_token}`)
            .append('X-Referrer', window.location.href.includes('/gamelaunch?redirectDomain') ? window.location.href.split('?')[0] : window.location.href)
        });

        let user_data = JSON.parse(localStorage.getItem('user_data'));
        let push_noti = user_data?.settings?.push_noti ?? true;

        if (push_noti && localStorage.getItem('os-player-id') === null) {
          window.OneSignal?.push(function () {
            window.OneSignal.isPushNotificationsEnabled(function (isEnabled) {
              if (isEnabled) {
                window.OneSignal.getUserId(function (userId) {
                  localStorage.setItem('os-player-id', userId);
                });
              }
            });
          });
        }

        if (localStorage.getItem('os-player-id')) {
          request = request.clone({
            headers: request.headers
              .append('OS-Player-ID', localStorage.getItem('os-player-id'))
          }
          );
        }

        if (marketingCampaign?.affid) {
          request = request.clone({
            headers: request.headers
              .append('affid', marketingCampaign?.affid)
          });
        }

        if (marketingCampaign?.clickid) {
          request = request.clone({
            headers: request.headers
              .append('clickid', marketingCampaign?.clickid)
          });
        }

        if (marketingCampaign?.retargeting) {
          request = request.clone({
            headers: request.headers
              .append('retargeting', marketingCampaign?.retargeting)
          });
        }

        if (marketingCampaign?.telemarketer_id) {
          request = request.clone({
            headers: request.headers
              .append('telemarketer-id', marketingCampaign?.telemarketer_id)
          });
        }

      }
    }

    if (((userAgent) && !isExternal) || request.url == '/campaign/updateclickcount') {
      if (!localStorage.getItem('language_code') && !localStorage.getItem('country_code')) {
        this.store.pipe(select(isLoggedIn)).subscribe(res => {
          if (res) {
            this.store.pipe(select(loggedUser)).subscribe(user => {
              this.languageCode = user.lang_code;
              this.countryCode = user.country_code;
              localStorage.setItem('language_code', user.lang_code);
              localStorage.setItem('country_code', user.country_code);
            });
          } else {
            this.languageCode = 'EN'; // Default
            this.countryCode = 'MY'; // Default
          }
        });
      } else {
        this.languageCode = localStorage.getItem('language_code');
        this.countryCode = localStorage.getItem('country_code');
      }

      if (JSON.parse(localStorage.getItem('user_browser')).isDesktop) {
        this.deviceType = 'Desktop';
      } else {
        this.deviceType = 'Mobile';
      }

      // provide login boolean
      this.store.pipe(select(isLoggedIn)).subscribe(res => {
        if (res) {
          this.isAuth = 'true';
        } else {
          this.isAuth = 'false';
        }
      });
      
      if (!request.url.includes(environment.translationDomain)) {
        request = request.clone({
          headers: request.headers
            .append('X-User-Agent', userAgent)
            .append('X-Language-Code', this.languageCode)
            .append('X-Country-Code', this.countryCode)
            .append('X-Device-Type', this.deviceType)
            .append('X-Site-Prefix', environment.sitePrefix)
            .append('X-Session-ID', this.sessionIdTracker)
            .append('X-Referrer', window.location.href.includes('/gamelaunch?redirectDomain') ? window.location.href.split('?')[0] : window.location.href)
            .append('fingerprint-id', fingerprintId)
            .append('X-Is-Auth', this.isAuth)
        });

        if (this.fingerprintHttpService.fingerprintId) {
          request = request.clone({
            headers: request.headers
              .append('fingerprint-id2', this.fingerprintHttpService.fingerprintId)
          })
        }
      }
    }

    // Check entry
    this.authService.checkTokenExpiration(expiry_datetime);

    if (!request.url.includes(environment.translationDomain)) {
      request = request.clone({
        url: isExternal ? request.url : (isIpAPI ? apiBase.substring(0, apiBase.lastIndexOf('/')) : apiBase) + request.url
      });
    }

    return next.handle(request).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
      tap(res => {
        if (res instanceof HttpResponse) {
          const currentApiVersion = localStorage.getItem('api_version');
          const localKeys = Object.entries(localStorage);
          const apiResponse: ApiResponse = res.body;
          if (apiResponse.version) {
            if (currentApiVersion === null) {
              localStorage.setItem('api_version', apiResponse.version);
            } else {
              if (currentApiVersion !== apiResponse.version) {
                localStorage.setItem('api_version', apiResponse.version);
                localKeys.map(storage => {
                  if (storage[0] !== 'user_token' &&
                    storage[0] !== 'verified_number' &&
                    storage[0] !== 'date_today' &&
                    storage[0] !== 'country_code' &&
                    storage[0] !== 'api_version' &&
                    storage[0] !== 'user_data' &&
                    storage[0] !== 'profile' &&
                    storage[0] !== 'language_code') {
                    localStorage.removeItem(storage[0]);
                    sessionStorage.clear();
                  }
                });
                window.location.reload();
              }
            }
          }
        }
      }),
      finalize(() => { })
    );

  }
}
