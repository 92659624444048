import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupDailyLimitOtpComponent } from '@shared/popup-daily-limit-otp/popup-daily-limit-otp.component';
import Swal from 'sweetalert2';
import { NumberVerificationHttpService } from '../../services/number-verification-http.service';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-withdraw-verification-modal',
  templateUrl: './withdraw-verification-modal.component.html',
  styleUrls: ['./withdraw-verification-modal.component.scss']
})
export class WithdrawVerificationModalComponent implements OnInit {
  svg: any = svgIconList;

  form: FormGroup;
  messages$ = this.numberVerificationHttpService.messages$;
  isSuccess = this.numberVerificationHttpService.isSuccess;
  toCloseDialog = false;
  counter: number;
  timer: any;
  disabled = '';  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      can_request_otp: boolean,
      new_mobile_number: string,
      request_type,
    },
    public dialogRef: MatDialogRef<WithdrawVerificationModalComponent>,
    private numberVerificationHttpService: NumberVerificationHttpService,
    private translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,  
  ) { }

  ngOnInit() {
    this.data.request_type = this.data?.request_type ?? 'phone_validation';
    this.formInit();
    if (sessionStorage.getItem('OTP_timer') !== null) {
      this.onTimer(Number(sessionStorage.getItem('OTP_timer')));
    }
  }

  onCloseDialog(toClose: boolean) {
    if (toClose) {
      this.dialogRef.close(this.toCloseDialog);
    }
  }

  onRequestOTP() {
    if (this.data.can_request_otp) {
      this.disabled = 'disabled';
      const newNumber = this.data.new_mobile_number ? this.data.new_mobile_number : null;
      const requestType = this.data.request_type ?? 'phone_verification';
      this.numberVerificationHttpService.requestOTP(newNumber, requestType).subscribe(res => {
        this.onTimer(res.otp_request_interval);
        this.isSuccess = true
      });
    } else {
      this.openDialogBy(PopupDailyLimitOtpComponent);
    }
  }

  onVerify() {
    this.numberVerificationHttpService.verifyOTP(this.form.value).subscribe(() => {
      this.toCloseDialog = true;
      localStorage.setItem('verified_number', 'true');
      const successText1 = this.data.request_type == 'phone_validation' ? this.translateService.instant('MOBILE VERIFICATION SUCCESSFUL') : this.translateService.instant('OTP VERIFICATION SUCCESSFUL');
      const successText2 = this.data.request_type == 'phone_validation' ? this.translateService.instant('Congratulations! Your mobile number has been successfully verified.') : this.translateService.instant('Successfully verified');
      Swal.fire({
        html: '<div class="msg-icon-greentick">' + this.svg.greenTickIcon + '</div>' + 
          '<div class="text-center mt-3">' +
          '<ul><li><h3 class="verification-title">'
          + successText1 +
          '</h3></li><li>'
          + successText2 + '</ul>' +
          '</div>',
        confirmButtonText: 'OK',
      }).then((result) => {
        this.onCloseDialog(true);
        if (result.isConfirmed && window.location.pathname === '/member/profile') {
          window.location.reload(); // need to reload profile page to get the latest status
        }
      });
    });
  }

  onTimer(startTime: number) {
    this.disabled = 'disabled';
    this.counter = startTime;
    this.eventEmitterService.onVerificationTimerEmitter();
    window.clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        window.clearInterval(this.timer);
        this.disabled = '';
      }
    }, 1000);
  }

  private openDialogBy(componentRef: any) {
    this.dialog.open(componentRef);
  }

  private formInit() {
    this.form = new FormGroup({
      otp: new FormControl(null, [Validators.required]),
      request_type: new FormControl(this.data.request_type),
    });
  }

}
