import { Component, OnInit, Inject } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-marquee-notification-modal',
  templateUrl: './marquee-notification-modal.component.html',
  styleUrls: ['./marquee-notification-modal.component.scss'],
})
export class MarqueeNotificationModalComponent implements OnInit {
  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination-popup', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: false // Do not turn on the loop, else the CTA button is not clickable
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { contents: any[] },
    public dialogRef: MatDialogRef<MarqueeNotificationModalComponent>,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {}

  onCloseDialog() {
    this.dialogRef.close();
  }
}
