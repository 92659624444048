
import svgIconList from 'assets/icons.json';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { ChangeDetectorRef,   Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { CountDownDetailsComponent } from '@shared/count-down/dialog/count-down-details/count-down-details.component';
import { GameListHttpService } from '@core/services/game-list-http.service';
import { tap } from 'rxjs/operators';
import { SeoService } from '@core/services/seo.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-jackpot-slider',
  templateUrl: './jackpot-slider.component.html',
  styleUrls: ['./jackpot-slider.component.scss'],
})
export class JackpotSliderComponent implements OnInit {
  svg: any = svgIconList;

  odometerConfig = {
    animation: 'slide',
    format: '(,ddd).dd',
    theme: 'default',
    auto: true
  }

  availableProvider = JSON.parse(localStorage.getItem('available_providers'));
  SGisActive = false; 

  gameCodeByJacpot = {
    ADV: 'S-PO02',
    CJ: 'S-EG02',
    MGC: 'S-LB01',
    GJ: 'S-DG03',
    FUFU: 'S-FO01'
  };
  jackpot = [];
  gameList  = {};
  gameProvider :any;

  countryCode = localStorage.getItem('country_code')?.toUpperCase();
  curentTime :any;

  contentHash = '';

  dateNow: any;
  dateEnd: any;

  gameCodeByJacpotPostion = [
    'MGC', // 1
    'FUFU', // 2
    'ADV', // 3
    'GJ', // 4
    'CJ' // 5
  ];

  customOptionsJacpot: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 1000,
    autoplaySpeed: 1000,
    autoplay: true,
    items: 1,
    nav: false,
    animateOut: 'animate__animated animate__slideOutLeft',
  };

  constructor(
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private gameCategoryHttpService: GameCategoryHttpService,
    public dialog: MatDialog,
    private gameListHttpService: GameListHttpService,
    private seoService: SeoService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if(this.availableProvider && this.availableProvider['SL'].find(x => x.game_provider_code == 'SG')) {
      this.SGisActive = true;
      this.gameProvider = this.availableProvider['SL'].find(x => x.game_provider_code == 'SG');
    }
    this.getGameList();
    //for testing number update
    // var plus = 0;
    this.eventEmitterService.jackpotDataEventEmitter.subscribe(data => {

      if(!this.availableProvider) {
        this.availableProvider = JSON.parse(localStorage.getItem('available_providers'));
        this.SGisActive = true;
        this.gameProvider = this.availableProvider['SL'].find(x => x.game_provider_code == 'SG');
      }

      if(this.jackpot.length == 0) {
        this.jackpot = data['jackpot']['data'];
      }else{
        // update only amount, instead array due to the slider will re-render problem
        if(this.jackpot.length > 0 && data['jackpot']['data'].length  > 0){
          let jackpotlist = this.jackpot;
          Object.keys(jackpotlist).forEach(function (key) {
            //for testing number update
            // plus = plus + 5;
            // data['jackpot']['data'][key]['amount']['MYR'] = data['jackpot']['data'][key]['amount']['MYR'] + plus;  

            // only amount different only will update
            if( JSON.stringify(data['jackpot']['data'][key]['amount']) !== JSON.stringify(jackpotlist[key]['amount']) ){
              jackpotlist[key]['amount'] = data['jackpot']['data'][key]['amount'];
            }
          });
          this.jackpot = jackpotlist;
        }
      }
    });
  }

  getOffset(el: any) {
    el = el.getBoundingClientRect();
    return {
      left: el.left + window.scrollX,
      top: el.top + window.scrollY,
      bottom: el.top + window.scrollY
    };
  }

  scroll() {
    const scrollTo = document.getElementById('content');
    const topHight = this.getOffset(scrollTo).top;
    window.scrollTo(0, topHight);
  }


  onLaunchGame(data: any, isDemo = false) {
    var gameCode = this.gameList[this.gameCodeByJacpot[data]]['code'];

    // To update the params on the URL
    window.history.replaceState({}, '', `/slots-game/${('Spade Gaming').replace(/\s/g, '').toLowerCase()}`);
    // Need to manually trigger seoService since we manually update the url without doing routing
    this.seoService.execute();
    var item = this.gameProvider;

    this.dateEnd = Date.parse(item.maintain_end);
    this.dateNow = new Date().getTime();

    if (localStorage.getItem('user_data') === null) {
      this.openDialogBy(LoginModalComponent);
    } else if (item.maintenance === 1 && item.code !== null && this.dateEnd > this.dateNow) {
      this.contentHash = Math.random().toString(36).substring(7);
      this.dialog.open(CountDownDetailsComponent, {
        width: '400px',
        data: {
          dateEnd: item.maintain_end,
          name: item.game_provider_name,
          time: item.maintain_time_left,
        }
      });
    } else if (item.maintenance === 1 && gameCode !== null && item.maintain_time_left === '') {
      this.contentHash = Math.random().toString(36).substring(7);
      this.dialog.open(CountDownDetailsComponent, {
        width: '400px',
        data: {
          name: item.game_provider_name,
          time: item.maintain_time_left,
        }
      });
    } else {
      localStorage.setItem('gameProviderCode', 'SG');
      localStorage.setItem('gameProviderId', item.game_provider_id);
      localStorage.setItem('gameCode', gameCode);
      localStorage.setItem('gameCategory', 'SL');
      localStorage.setItem('gameType', 'SLOTS');
      localStorage.setItem('gameMode', 'real');
      localStorage.setItem('lobbyUrl', location.href);
      this.onLaunchGameBy(item, gameCode, 'SLOTS', isDemo);
    }
  }  

  onLaunchGameBy(item: any, gameCode: string, gameType: string, isDemo = false, slotsType?: string) {
    if (gameCode !== null && slotsType !== 'APP') {
      this.router.navigate([]).then(() => { window.open('/gamelaunch', '_blank'); });
    } else {
      this.router.navigate(['/slots-game/' + (item.game_provider_name).replace(/\s/g, '').toLowerCase()], { state: { item: item, slotsType: slotsType } });
    }  
  }  

  onGetCurrencyCode() {
    let currency_code = 'MYR'; //default MYR

    if (localStorage.getItem('country_code')?.toUpperCase() == 'SG') {
      currency_code = 'SGD';
    } else if (localStorage.getItem('country_code')?.toUpperCase() == 'ID') {
      currency_code = 'IDR';
    }
    return currency_code;
  }

  transferAmount(data){
    let amount = +data.toFixed(2);

    //Workaround to deal with floating point arithmetic issue
    if (Math.round(amount * 100) % 10 == 0) {
      amount = amount + 0.01
    }

    return amount;
  }

  getGameList() {
      var games = {};
      // hard code to get Spade game,slot gamelist
      this.gameListHttpService.getGamesBy('SG', 'SL').pipe(
        tap(res => {
          Object.keys(res).forEach(function (key) {
            if(res[key]['status'] == "ACTIVE") games[res[key]['code']]  = res[key];
          });
          this.gameList = games;
        })
      ).subscribe();
  }

  private openDialogBy(componentRef: any) {
    this.dialog.open(componentRef);
  }
}
